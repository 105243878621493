import SvgSprite from '@sm360/inventory-listing-360/dist/assets/svg-sprite.svg'
import React from 'react'
import SVG from 'react-inlinesvg'

import ListingView from '../../views/listing/Listing.view'
import { runtimeConfig } from '../config/runtimeConfig'

const ListingSSR = ({ packageVersion }) => {
    React.useEffect(() => {
        if (typeof window === 'undefined') {
            global.window = {}
        }
    }, [])
    const svgBaseUrl = runtimeConfig.REACT_APP_ASSETS_PATH ? `${runtimeConfig.REACT_APP_ASSETS_PATH}/inventory-listing-360` : ''
    return (
        <>
            <SVG
                src={`${svgBaseUrl}${SvgSprite}`}
                style={{ display: 'none' }}
                onError={(error) => console.log('svg error', error)}
            />

            {/* TODO: remove the props orgUnitId when Delta return the version */}
            <ListingView packageVersion={packageVersion} />
        </>
    )
}

export default ListingSSR
