import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import BreadcrumbItem from './BreadcrumbItem'
import { ListingContext } from '../../configs/listing-view/state.manager'

const Breadcrumb = ({ extraClasses }) => {
    const {
        state: {
            filterMetaData,
            vehicleCriteria,
            selectedHubCategory,
            pathUrlInfos: { inventoryUrl, makeName, modelName, makeUrl },
        },
    } = React.useContext(ListingContext)

    const baseClass = 'il-breadcrumb'
    const classNames = clsx(baseClass, extraClasses)

    const hubCategoryName = selectedHubCategory?.name

    const getNameFromCriteria = (type) => {
        const criteria = vehicleCriteria[type]
        if (!criteria) return null
        if (vehicleCriteria[type] && vehicleCriteria[type].length > 1) return null
        const filters = filterMetaData.find(({ name }) => name === type)
        const currentUrl = filters?.metadatas.find(({ code }) => code === criteria[0])
        return currentUrl?.label
    }

    return (
        <nav className={classNames} aria-label='breadcrumb' role='navigation'>
            <ol className={`${baseClass}__list`}>
                <BreadcrumbItem isFirst />

                {hubCategoryName && (
                    <BreadcrumbItem label={hubCategoryName} url={makeName ? inventoryUrl : ''} isLast={!makeName} />
                )}

                {getNameFromCriteria('makeId') && (
                    <BreadcrumbItem label={getNameFromCriteria('makeId')} url={modelName ? makeUrl : ''} isLast={!modelName} />
                )}

                {getNameFromCriteria('makeId') && getNameFromCriteria('modelId') && (
                    <BreadcrumbItem label={getNameFromCriteria('modelId')} isLast />
                )}
            </ol>
        </nav>
    )
}

Breadcrumb.propTypes = {
    /** Nav ExtraClass */
    extraClasses: PropTypes.string,
}

Breadcrumb.defaultProps = {
    extraClasses: '',
}

export default Breadcrumb
