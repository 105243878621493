import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

const SerialNumber = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            vehicle: { serialNo },
        },
    } = React.useContext(VehicleCardContext)

    const classNames = clsx('vc-serialNumber', extraClasses)

    if (serialNo)
        return (
            <p className={classNames} {...extraParams}>
                {serialNo}
            </p>
        )

    return null
}

SerialNumber.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

SerialNumber.defaultProps = {
    extraClasses: '',
}

export default SerialNumber
