import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Icon } from '@sm360/ui-library'
import { useTranslation } from '@sm360/hooks'

import { ListingContext } from '../../configs/listing-view/state.manager'

import BreadcrumbSeparator from './BreadcrumbSeparator'

const BreadcrumbItem = ({ label, url, isFirst, isLast, ...extraParams }) => {
    const { t } = useTranslation()

    const {
        state: {
            pathUrlInfos: { langUrl },
            XMSConfig: {
                configuration: { breadcrumbShowHomePicto },
            },
        },
    } = React.useContext(ListingContext)

    const baseClass = 'il-breadcrumbItem'
    const listClasses = clsx(baseClass, (isLast || !url) && '-active')
    const linkClasses = clsx(`${baseClass}__link`, isFirst && '-isFirst')
    const separatorClasses = clsx(`${baseClass}__separator`)
    const iconClasses = clsx(`${baseClass}__icon`)
    const labelClasses = clsx(`${baseClass}__label`)

    if (!label && !isFirst) return null

    if (isFirst) {
        return (
            <li className={listClasses} {...extraParams}>
                <a href={langUrl} title={t('common.home')} className={linkClasses}>
                    {breadcrumbShowHomePicto ? <Icon name='home-outline' className={iconClasses} /> : t('common.home')}
                </a>
            </li>
        )
    }

    if (isLast) {
        return (
            <li className={listClasses} aria-current='page' {...extraParams}>
                <BreadcrumbSeparator extraClasses={separatorClasses} />

                <span className={labelClasses}>{label}</span>
            </li>
        )
    }

    return (
        <li className={listClasses} {...extraParams}>
            <BreadcrumbSeparator extraClasses={separatorClasses} />

            <a href={url} title={label} className={linkClasses}>
                {label}
            </a>
        </li>
    )
}

BreadcrumbItem.propTypes = {
    /** Label */
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** URL */
    url: PropTypes.string,
    /** is First Element */
    isFirst: PropTypes.bool,
    /** is Last Element */
    isLast: PropTypes.bool,
}

BreadcrumbItem.defaultProps = {
    label: '',
    url: '',
    isLast: false,
    isFirst: false,
}

export default BreadcrumbItem
