import React from 'react'
import { hydrateRoot } from 'react-dom/client'

import ListingSSR from './views/ListingSSR'
import { ListingProvider } from '../configs/listing-view/state.manager'
import { I18nextProvider, i18nConfig } from '../internationalization/src'

import '@sm360/inventory-listing-360/dist/views/listing/listing.view.css'

const preloadedState = window.__PRELOADED_STATE__
delete window.__PRELOADED_STATE__

const { packageVersion, initialData } = preloadedState

hydrateRoot(
    document.getElementById('widget-inventory-listing-360'),
    <I18nextProvider i18n={i18nConfig}>
        <ListingProvider initialData={initialData}>
            <ListingSSR packageVersion={packageVersion} />
        </ListingProvider>
    </I18nextProvider>
)

if (module.hot) {
    module.hot.accept()
}
