import axios from 'axios'

import { axiosErrorHandler, buildInventoryUrl, buildUrlWithParameters } from '../../utils/handle-url.utils'

const config = {
    headers: { authorization: `Basic c20zNjA6c20zNjA=` },
}

/**
 * Get Inventory Filters
 * @param { String } hub - Name of hubCategory
 * @param { String } lang - Language
 * @param { Object } params - The params object
 * @returns {Promise<{}>}
 */
export const getInventoryFiltersAPIUrl = async (hub, lang, params = {}, domain) => {
    if (!hub) return null

    let apiResponse = {}

    const apiUrl = `/${lang}/api/vehicleinventory/filters/${buildInventoryUrl(lang, hub)}`

    const url = domain || window?.web_domain
    const urlComplete = `${url.concat(apiUrl)}`

    const urlWithParams = buildUrlWithParameters(urlComplete, params)
    const response = await axios.get(urlWithParams, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data
    }
    return apiResponse
}

/**
 * Get Hubs List
 * @param { String } hub - Name of hubCategory
 * @param { String } lang - Language
 * @returns {Promise<{}>}
 */
export const getHubsListAPIUrl = async (hub, lang, domain) => {
    if (!hub) return null

    let apiResponse = {}

    const inventoryUrl = buildInventoryUrl(lang, hub)
    const apiUrl =
        lang === 'fr'
            ? `/fr/a-vendre/all/api/hubs/list/${inventoryUrl}?includeNotShowOnWebsite=true`
            : `/en/for-sale/all/api/hubs/list/${inventoryUrl}?includeNotShowOnWebsite=true`

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    const response = await axios.get(urlComplete, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data
    }
    return apiResponse
}

/**
 * Get Group Categories
 * @param { String } hub - Name of hubCategory
 * @param { String } lang - Language
 * @returns {Promise<{}>}
 */
export const getGroupCategoriesAPIUrl = async (hub, lang, domain) => {
    if (!hub) return null

    let apiResponse = []
    const apiUrl = lang === 'fr' ? `/fr/a-vendre/all/api/hub/${hub}/related` : `/en/for-sale/all/api/hub/${hub}/related`

    const location = domain || window?.web_domain

    const urlComplete = location ? `${location.concat(apiUrl)}` : apiUrl

    try {
        const response = await axios.get(urlComplete, config)
        if (response) {
            apiResponse = response.data
        }
        return apiResponse
    } catch (e) {
        return apiResponse
    }
}
