import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ListingContext } from '../../configs/listing-view/state.manager'

import { Trans } from 'react-i18next'
import TotalVehiclesSkeleton from './TotalVehicles.skeleton'

const TotalVehicles = ({ extraClasses }) => {
    const {
        state: { pagination, isFetching },
    } = React.useContext(ListingContext)

    const baseClass = 'il-totalVehicles'
    const classNames = clsx(baseClass, extraClasses)

    const totalVehicles = pagination?.numberOfItems || 0

    if (isFetching) return <TotalVehiclesSkeleton />

    return (
        <p className={classNames}>
            <Trans i18nKey={totalVehicles === 1 ? 'filters.vehicleFound' : 'filters.vehiclesFound'}>
                <span className={`${baseClass}__count`}>{{ totalVehicles }}</span>
                <span className={`${baseClass}__label`}></span>
            </Trans>
        </p>
    )
}

TotalVehicles.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

TotalVehicles.defaultProps = {
    extraClasses: '',
}

export default TotalVehicles
