import React from 'react'
import clsx from 'clsx'
import { Icon } from '@sm360/ui-library'

const BreadcrumbSeparator = () => {
    const baseClasses = 'il-breadcrumbSeparator'
    const classNames = clsx(baseClasses)

    return (
        <span className={classNames}>
            <Icon name='icon-breadcrumb-separator' />
        </span>
    )
}

export default BreadcrumbSeparator
