import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useTranslation } from '@sm360/hooks'
import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

const StockNumber = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            vehicle: { stockNo },
        },
    } = React.useContext(VehicleCardContext)

    const classNames = clsx('vc-stockNumber', extraClasses)

    const { t } = useTranslation()

    if (stockNo)
        return (
            <p className={classNames} {...extraParams}>
                {t('vehicle.specifications.stockNumber')}: {stockNo}
            </p>
        )

    return null
}

StockNumber.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

StockNumber.defaultProps = {
    extraClasses: '',
}

export default StockNumber
