import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import formatNumber from '../../../../../../utils/format-number.utils'

const PercentRate = ({ rate, extraClasses }) => {
    const baseClass = 'vc-percentRate'
    const classNames = clsx(baseClass, extraClasses)

    return <p className={classNames}>{formatNumber(rate, 'percent')}</p>
}

PercentRate.propTypes = {
    /** Frequency of payments */
    rate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

PercentRate.defaultProps = {
    extraClasses: '',
    rate: '',
}

export default PercentRate
