import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

import { Img } from '@sm360/ui-library'

import { getImageUrl } from '../../../../utils/image-url-generator.utils'

const VehicleCertified = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            vehicle: { certified, make, newVehicle },
            dealerInfos: { orgUnitMakes, lang },
        },
    } = React.useContext(VehicleCardContext)

    const classNames = clsx('vc-vehicleCertified', extraClasses)

    if (!certified || newVehicle) return null

    const certifiedMake = orgUnitMakes?.includes(make?.slug) ? `${make.slug.toLowerCase()}` : 'sm360'

    return (
        <div className={classNames} {...extraParams}>
            <Img
                src={getImageUrl(`/${certifiedMake}/${certifiedMake}-certified-color-best-${lang}.png`, 'h45', 'manufacturer')}
            />
        </div>
    )
}

VehicleCertified.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

VehicleCertified.defaultProps = {
    extraClasses: '',
}

export default VehicleCertified
