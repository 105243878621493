import React from 'react'
import PropTypes from 'prop-types'

import { Img } from '@sm360/ui-library'
import { useDeviceType } from '@sm360/hooks'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { getImageUrl } from '../../utils/image-url-generator.utils'
import { isExternalUrl } from '../../utils/handle-url.utils'

const PromoBanner = ({ promoList, isInfiniteLoop }) => {
    const { device, responsive } = useDeviceType()

    if (!promoList || !promoList.length) return null

    // const responsive = {
    //     desktop: {
    //         breakpoint: { max: 3000, min: 1024 },
    //         items: 1,
    //     },
    //     tablet: {
    //         breakpoint: { max: 1024, min: 464 },
    //         items: 1,
    //     },
    //     mobile: {
    //         breakpoint: { max: 464, min: 0 },
    //         items: 1,
    //     },
    // }

    const CustomRightArrow = ({ onClick }) => {
        return (
            <div
                className='il-promoBanner__arrow il-promoBanner__arrow--right'
                role='button'
                tabIndex={0}
                onClick={() => onClick()}
                aria-hidden='true'
            ></div>
        )
    }

    const CustomLeftArrow = ({ onClick }) => {
        return (
            <div
                className='il-promoBanner__arrow il-promoBanner__arrow--left'
                role='button'
                tabIndex={0}
                onClick={() => onClick()}
                aria-hidden='true'
            ></div>
        )
    }

    const displayArrowAndDots = promoList && promoList.length > 1

    return (
        <Carousel
            additionalTransfrom={0}
            arrows={displayArrowAndDots}
            autoPlaySpeed={3000}
            centerMode={false}
            className='il-promoBanner'
            containerClass='il-promoBanner__container'
            dotListClass='il-promoBanner__dotList'
            draggable
            focusOnSelect={false}
            infinite={isInfiniteLoop}
            itemClass=''
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={displayArrowAndDots}
            sliderClass=''
            slidesToSlide={1}
            swipeable
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
        >
            {promoList.map((promo) => {
                const image = {
                    mobile: getImageUrl(promo.imgUrl3, '', 'promo'),
                    tablet: getImageUrl(promo.imgUrl2, '', 'promo'),
                    desktop: getImageUrl(promo.imgUrl, '', 'promo'),
                }

                const CustomTag = promo.url1 ? 'a' : 'div'

                return (
                    <CustomTag
                        href={promo.url1}
                        target={isExternalUrl(promo.url1) ? '_blank' : undefined}
                        className='il-promoBanner__slide'
                        key={promo.id}
                    >
                        {<Img src={image[device]} alt={promo.title} className='il-promoBanner__img' />}
                    </CustomTag>
                )
            })}
        </Carousel>
    )
}

PromoBanner.propTypes = {
    promoList: PropTypes.array,
    isInfiniteLoop: PropTypes.bool,
    onClick: PropTypes.func,
}

PromoBanner.defaultProps = {
    promoList: [],
    isInfiniteLoop: true,
}

export default PromoBanner
