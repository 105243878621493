import React from 'react'

import { ListingContext } from '../../configs/listing-view/state.manager'
import Breadcrumb from '../breadcrumb/Breadcrumb'
import FilterSticky from '../filter-sticky/FilterSticky'
import Filters from '../filters/Filters'
import FiltersReset from '../filters-reset/FiltersReset'
import InventoryHeader from '../heading/InventoryHeader'
import PageSorting from '../page-sorting/PageSorting'
import Pagination from '../pagination/Pagination'
import PromoBanner from '../promo-banner/PromoBanner'
import SeoContent from '../seo/SeoContent'
import TotalVehicles from '../total-vehicles/TotalVehicles'
import VehicleSorting from '../vehicle-sorting/VehicleSorting'
import Vehicles from '../vehicles/Vehicles'

const ListingAlpha = () => {
    const {
        state: { promoBanner },
    } = React.useContext(ListingContext)

    const [isFilterOpen, setIsFilterOpen] = React.useState(false)

    const handleStickyFilterClick = () => {
        setIsFilterOpen(!isFilterOpen)
    }

    return (
        <main className='il-listingAlpha' data-reset-parents-overflow>
            {/* Section Top */}
            <div className='il-listingAlpha__top'>
                {/* Section Breadcrumb */}
                <section className='il-listingAlpha__breadcrumb -wrapper'>
                    <Breadcrumb extraClasses='il-listingAlpha__breadcrumb' />
                </section>

                {/* Section Promo Banner */}
                <section className='il-listingAlpha__promoBanner'>
                    <PromoBanner promoList={promoBanner} />
                </section>

                {/* Section Introduction */}
                <section className='il-listingAlpha__heading -wrapper'>
                    <InventoryHeader />
                </section>
            </div>

            {/* Section Container */}
            <div className='il-listingAlpha__container -wrapper'>
                {/* Section Sidebar */}
                <FilterSticky onClick={handleStickyFilterClick} />

                <section className='il-listingAlpha__sidebar'>
                    {/* Section HubCategories */}

                    {/* Section Filters */}
                    <Filters isOpen={isFilterOpen} onClose={handleStickyFilterClick} />
                </section>

                {/* Section Main Content */}
                <section className='il-listingAlpha__mainContent'>
                    <FiltersReset extraClasses='il-listingAlpha__filtersReset' />

                    <div className='il-listingAlpha__vehicleSortSection'>
                        <TotalVehicles extraClasses='il-listingAlpha__totalVehicles' />

                        <div className='il-listingAlpha__vehicleAndPageSorting'>
                            <VehicleSorting extraClasses='il-listingAlpha__vehicleSorting' />
                            <PageSorting />
                        </div>
                    </div>

                    <Vehicles extraClasses='il-listingAlpha__vehicles' />

                    <Pagination extraClasses='il-listingAlpha__pagination' />
                </section>
            </div>
            <div className='il-listingAlpha__footer'>
                <section className='il-listingAlpha__footerContent'>
                    <SeoContent />
                </section>
            </div>
        </main>
    )
}

export default ListingAlpha
