import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

const conditionIsActive = (conditionListActive, condition) => {
    return conditionListActive.some(({ conditionDefinition }) => conditionDefinition.code === condition)
}

const conditionInfo = (condition, classNames, extraParams) => {
    const { label, backgroundColor, fontColor } = condition

    return (
        <span
            className={classNames}
            style={{
                backgroundColor,
                color: fontColor,
            }}
            {...extraParams}
        >
            {label}
        </span>
    )
}

const VehicleConditions = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            XMSConfig: { conditionsConfigurations },
            vehicle: { newVehicle, certified, demo },
        },
    } = React.useContext(VehicleCardContext)

    const classNames = clsx('vc-vehicleConditions', extraClasses)

    // TODO: Need to have config from XMS-Delta to display demo as new or used
    // For now with !demo flag added into the condition, it will display Used label for all demo vehicles
    const hasNewCondition = newVehicle && !demo && conditionIsActive(conditionsConfigurations, 'new')
    const hasUsedCondition = !certified && conditionIsActive(conditionsConfigurations, 'used')

    if (hasNewCondition) {
        const newConditionInfo = conditionsConfigurations.filter(
            ({ conditionDefinition }) => conditionDefinition.code === 'new'
        )[0]
        return conditionInfo(newConditionInfo, classNames, extraParams)
    }

    if (hasUsedCondition) {
        const usedConditionInfo = conditionsConfigurations.filter(
            ({ conditionDefinition }) => conditionDefinition.code === 'used'
        )[0]
        return conditionInfo(usedConditionInfo, classNames, extraParams)
    }

    return null
}

VehicleConditions.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

VehicleConditions.defaultProps = {
    extraClasses: '',
}
export default VehicleConditions
