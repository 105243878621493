import { useTranslation } from '@sm360/hooks'
import { Button } from '@sm360/ui-library'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import VehicleSorting from '../vehicle-sorting/VehicleSorting'

const FilterSticky = ({ extraClasses, onClick }) => {
    const baseClass = 'il-filterSticky'
    const classNames = clsx(baseClass, extraClasses)
    const { t } = useTranslation()

    return (
        <div className={classNames}>
            <Button className={`${baseClass}__button`} label={t('filters.filter')} onClick={onClick} />
            <VehicleSorting />
        </div>
    )
}

FilterSticky.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Click function for button */
    onClick: PropTypes.func,
}

FilterSticky.defaultProps = {
    extraClasses: '',
    onClick: () => {},
}

export default FilterSticky
