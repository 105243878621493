export const isObject = (obj) => {
    return typeof obj === 'object' && obj !== null && !Array.isArray(obj)
}

export const isFunction = (variable) => {
    return typeof variable === 'function'
}

export const isString = (variable) => {
    return typeof variable === 'string'
}

export const isArray = (variable) => {
    return Array.isArray(variable)
}

export const isBoolean = (variable) => {
    return typeof variable === 'boolean'
}

export const isNumber = (val) => {
    return typeof val === 'number' && !isNaN(val)
}

export const sortBy = (sortBy) => (a, b) => a[sortBy] > b[sortBy] ? 1 : -1

export const isEmptyObject = (obj) => Object.values(obj).every((x) => x === null || x === '')

export const WindowProxy = typeof window !== 'undefined' && window.toString() === '[object Window]' && window

export const getPathname = () => WindowProxy.location.pathname
