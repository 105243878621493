import React from 'react'
import PropTypes from 'prop-types'

const StructuredData = ({ data }) => {
    return <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
}

StructuredData.propTypes = {
    /** All data to be translated into JSON */
    data: PropTypes.object,
}

StructuredData.defaultProps = {
    data: {},
}

export default StructuredData
