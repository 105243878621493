import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Icon } from '@sm360/ui-library'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'
import formatNumber from '../../../../utils/format-number.utils'

const TOTAL_REBATES = 'total_rebates'

const VehicleRebates = ({ extraClasses }) => {
    const {
        state: {
            vehicle: { paymentOptions },
            XMSConfig: { taggingConfigurations },
        },
    } = React.useContext(VehicleCardContext)

    const baseClass = 'vc-vehicleRebates'
    const classNames = clsx(baseClass, extraClasses)

    const rebatesData = taggingConfigurations && taggingConfigurations?.find((config) => config.code === TOTAL_REBATES)
    const { backgroundColor, fontColor } = rebatesData || {}

    if (!rebatesData || !paymentOptions?.bestIncentives || paymentOptions?.bestIncentives?.total <= 0) return null

    return (
        <div className={classNames} style={{ backgroundColor }}>
            <Icon name='rebate' className={`${baseClass}__icon`} />
            <span className={`${baseClass}__infos`} style={{ color: fontColor }}>
                {`${rebatesData.label} ${formatNumber(paymentOptions?.bestIncentives?.total, 'priceDigitsAuto')}`}
            </span>
        </div>
    )
}

VehicleRebates.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

VehicleRebates.defaultProps = {
    extraClasses: '',
}

export default VehicleRebates
