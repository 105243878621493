import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Icon } from '@sm360/ui-library'

import { ListingContext } from '../../configs/listing-view/state.manager'

import PaginationItem from './PaginationItem'

const Pagination = ({ extraClasses }) => {
    const {
        state: { pagination },
    } = React.useContext(ListingContext)

    const baseClass = 'il-pagination'
    const classNames = clsx(baseClass, extraClasses)

    const numberOfPages = pagination?.numberOfPages || 0
    const pageNumber = pagination?.pageNumber || 0

    if (!numberOfPages || numberOfPages <= 1) return null

    return (
        <section className={classNames}>
            {numberOfPages > 2 && (
                <PaginationItem currentPage={1} isDisabled={pageNumber === 1} extraClasses='il-paginationItem__arrow'>
                    <Icon name='arrow-left-double' className='il-paginationItem__icon' />
                </PaginationItem>
            )}

            <PaginationItem currentPage={pageNumber - 1} isDisabled={pageNumber === 1} extraClasses='il-paginationItem__arrow'>
                <Icon name='arrow-left' className='il-paginationItem__icon' />
            </PaginationItem>

            {numberOfPages > 1 && pageNumber > 1 && (
                <PaginationItem currentPage={pageNumber - 1}>
                    {pageNumber - 1 > 1 && '...'}
                    {pageNumber - 1}
                </PaginationItem>
            )}

            <PaginationItem extraClasses='-active'>{pageNumber}</PaginationItem>

            {numberOfPages > 1 && pageNumber < numberOfPages && (
                <PaginationItem currentPage={pageNumber + 1}>
                    {pageNumber + 1}
                    {pageNumber + 1 < numberOfPages && '...'}
                </PaginationItem>
            )}

            <PaginationItem
                currentPage={pageNumber + 1}
                isDisabled={pageNumber === numberOfPages}
                extraClasses='il-paginationItem__arrow'
            >
                <Icon name='arrow-right' className='il-paginationItem__icon' />
            </PaginationItem>

            {numberOfPages > 2 && (
                <PaginationItem
                    currentPage={numberOfPages}
                    isDisabled={pageNumber === numberOfPages}
                    extraClasses='il-paginationItem__arrow'
                >
                    <Icon name='arrow-right-double' className='il-paginationItem__icon' />
                </PaginationItem>
            )}
        </section>
    )
}

Pagination.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

Pagination.defaultProps = {
    extraClasses: '',
}

export default Pagination
