export const FILTER_OPTIONS_TYPES = {
    CHECKBOX: 'checkbox',
    RANGE: 'range',
}
export const FILTER_OPTIONS_REMOVED_FROM_URL = ['namedSorting', 'limit']

export const INVENTORY_TYPE = {
    NEW: 'new',
    USED: 'used',
    ALL: 'all',
}

export const FILTER_GROUP_URL = {
    en: 'group-',
    fr: 'groupe-',
}

export const FILTER_LOCATIONS_EXCLUDE = ['8685', '8686', '8659', '8681', '8671', '8672', '2985', '6334', '8680', '4161']

// display only for Humberview Portal and AutoPark Group
export const AVAILABLE_AT_WHITELIST = [8659, 8739, 4255, 4098]
