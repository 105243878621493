import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'
import { Trans } from 'react-i18next'
import { AVAILABLE_AT_WHITELIST } from '../../../../constants/filterOptions'

const DealerName = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            vehicle: { primaryOrganizationUnit },
            dealerInfos: { orgUnitId },
        },
    } = React.useContext(VehicleCardContext)

    const baseClass = 'vc-dealerName'
    const classNames = clsx(baseClass, extraClasses)
    const displayLocation = AVAILABLE_AT_WHITELIST.includes(parseInt(orgUnitId)) && primaryOrganizationUnit?.name
    if (displayLocation)
        return (
            <div className={classNames} {...extraParams}>
                <Trans i18nKey={'vehicle.availableAt'}>
                    <span className={`${baseClass}__available`}>{{ name: primaryOrganizationUnit.name }}</span>
                    <span className={`${baseClass}__name`}></span>
                </Trans>
            </div>
        )

    return null
}

DealerName.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

DealerName.defaultProps = {
    extraClasses: '',
}

export default DealerName
