import React from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'

import clsx from 'clsx'

import { Button, Icon } from '@sm360/ui-library'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

const CompareButton = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            XMSConfig: { inventoryNewCompareConfiguration, inventoryUsedCompareConfiguration },
            vehicle: { vehicleId, newVehicle, vehicleStatus },
        },
    } = React.useContext(VehicleCardContext)

    const {
        displayOnVLP,
        labelAdd: labelChecked,
        labelRemove: labelUnchecked,
        picto,
    } = newVehicle ? inventoryNewCompareConfiguration : inventoryUsedCompareConfiguration

    const [isActive, setIsActive] = React.useState(false)

    let compareCookieValues = ''
    let compareType = ''

    const btnLabel = isActive ? labelUnchecked : labelChecked

    const baseClass = 'vc-compareButton'
    const classNames = clsx(baseClass, isActive ? '-active' : '', extraClasses)

    if (newVehicle) {
        compareCookieValues = Cookies.get('newInventoryCars')
        compareType = 'new-inventory'
    } else {
        compareCookieValues = Cookies.get('usedCars')
        compareType = 'used'
    }

    React.useEffect(() => {
        if (compareCookieValues !== undefined && compareCookieValues.includes(vehicleId)) setIsActive(true)
    }, [])

    const handleClick = () => {
        setIsActive(!isActive)
    }

    if (!displayOnVLP || vehicleStatus === 'SOLD') return null

    return (
        <Button
            size='tiny'
            className={classNames}
            onClick={handleClick}
            data-comparetool-trigger=''
            data-comparetool-type={compareType}
            data-comparetool-trimid={vehicleId}
            {...extraParams}
        >
            <Icon name={picto} className={`${baseClass}__icon`} />

            <span className={`${baseClass}__label`}>{btnLabel}</span>
        </Button>
    )
}

CompareButton.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

CompareButton.defaultProps = {
    extraClasses: '',
}

export default CompareButton
