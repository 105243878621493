import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useTranslation } from '@sm360/hooks'
import { RadioGroup } from '@sm360/ui-library'
import Heading from '../heading/Heading'
import { ListingContext } from '../../configs/listing-view/state.manager'
import { changeUrl, findSelectedHubCategory } from '../../utils/handle-url.utils'
import { FILTER_GROUP_URL, FILTER_LOCATIONS_EXCLUDE } from '../../constants/filterOptions'

const FilterLocations = ({ extraClasses }) => {
    const {
        state: {
            hubCategories,
            hubName,
            vehicleCriteria,
            dealerInfos: { orgUnitName, orgUnitId },
        },
        getEmptyCriteria,
        dispatch,
    } = React.useContext(ListingContext)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const baseClass = 'il-filterLocations'
    const classNames = clsx(baseClass, extraClasses)
    const options = [
        {
            id: 'dealer',
            label: orgUnitName,
            value: 'dealer',
        },
        {
            id: 'group',
            label: t('filters.locations.all'),
            value: 'group',
        },
    ]
    const excludeOrganization = FILTER_LOCATIONS_EXCLUDE.includes(orgUnitId)
    const currentLocation = hubName.search('group') >= 0 ? 'group' : 'dealer'

    const getNewHubName = (value) => {
        const localizedGroupUrl = FILTER_GROUP_URL[language]
        const firstCategory = hubCategories[0]?.urlMapping
        const hubName = value === 'group' ? `${localizedGroupUrl}${firstCategory}` : firstCategory?.replace(localizedGroupUrl, '')
        return hubName
    }

    const handleRadioChange = (value) => {
        const newHubName = getNewHubName(value)
        const newCriteria = getEmptyCriteria(vehicleCriteria)
        const selectedHubCategory = findSelectedHubCategory(newHubName, hubCategories, language)
        changeUrl({}, [], language, newHubName, true)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: newCriteria })
        dispatch({ type: 'updateHubName', hubName: newHubName, selectedHubCategory })
        dispatch({ type: 'forceUpdate', forceUpdate: true })
        dispatch({ type: 'resetHubCategories', resetHubCategories: true })
    }

    if (excludeOrganization || (hubCategories && hubCategories?.length === 0) || !hubCategories) return null

    return (
        <div className={classNames}>
            <Heading Tag='p' extraClasses={`${baseClass}__heading heading3`} variant='heading3'>
                {t('filters.locations')}
            </Heading>
            <RadioGroup
                name='locations'
                options={options}
                onChange={(value) => handleRadioChange(value)}
                checked={currentLocation}
            />
        </div>
    )
}

FilterLocations.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

FilterLocations.defaultProps = {
    extraClasses: '',
}

export default FilterLocations
