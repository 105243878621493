import PropTypes from 'prop-types'
import React from 'react'

import { PromotionCardVLP, VehicleCardBravo } from '@sm360/components'
import VehicleCardAlpha from '../../components/vehicle-cards/VehicleCardAlpha'

import { VehicleCardContext } from '../../configs/vehicle-card-view/state.manager'
import { saveQuote, getCalculatorApiUrl } from '../../apis/showroom360/showroom360.service'

const VehicleCardView = ({ packageVersion, isCalculatorModalOpen, promoCards, vehicle }) => {
    /* TODO: remove the props orgUnitId when Delta return the version */
    const version = packageVersion === 'premium' ? 'bravo' : 'alpha'
    const isPromoTile = !vehicle?.vehicleId && promoCards?.length > 0

    const {
        state: { vehicleCtas, dealerInfos, XMSConfig },
    } = React.useContext(VehicleCardContext)

    return (
        <>
            {version === 'alpha' && <VehicleCardAlpha />}

            {version === 'bravo' &&
                (isPromoTile ? (
                    <PromotionCardVLP promotion={vehicle} />
                ) : (
                    <VehicleCardBravo
                        isCalculatorModalOpen={isCalculatorModalOpen}
                        vehicle={vehicle}
                        vehicleCtas={vehicleCtas}
                        dealerInfos={dealerInfos}
                        XMSConfig={XMSConfig}
                        saveQuote={saveQuote}
                        getCalculatorApiUrl={getCalculatorApiUrl}
                    />
                ))}
        </>
    )
}

VehicleCardView.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
    packageVersion: PropTypes.string,
    /** Flag for Calculator Modal */
    isCalculatorModalOpen: PropTypes.bool,
}

VehicleCardView.defaultProps = {
    extraClasses: '',
    packageVersion: '',
}

export default VehicleCardView
