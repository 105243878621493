import React from 'react'
import { useTranslation } from '@sm360/hooks'
import { isEmptyObject } from '@sm360/helpers'

import { ListingContext } from '../../configs/listing-view/state.manager'
import { getHubCategory, setMetaData } from '../../utils/global-functions.utils'
import { INVENTORY_TITLE_TYPES } from '../../constants/inventoryTitleType'
import { FILTER_GROUP_URL } from '../../constants/filterOptions'

import Heading from './Heading'

const InventoryHeader = () => {
    const {
        state: { hubName, vehicleCriteria, filters, dealerInfos, seoContent, filterMetaData, hubCategories },
    } = React.useContext(ListingContext)

    const {
        i18n: { language },
    } = useTranslation()

    const fallbackContent = {
        'page.inventory.fallback.h1': {
            en: '{inventoryHubType} Inventory at {name} in {city}',
            fr: '{inventoryHubType} Inventaire à {name} à {city}',
        },
    }
    const combinedContent = { ...seoContent, ...fallbackContent }

    const { name: hubCategoryName, vehicleInventoryHubCategory } = getHubCategory(hubCategories, hubName, language) || ''
    const slug = vehicleInventoryHubCategory && vehicleInventoryHubCategory?.slug.toLowerCase()
    const isGroupHub = hubName.search(`${FILTER_GROUP_URL[language]}`) >= 0

    const [h1Content, setH1Content] = React.useState(
        setMetaData(
            INVENTORY_TITLE_TYPES.H1,
            slug,
            vehicleCriteria,
            filters,
            dealerInfos?.orgUnitName || '',
            dealerInfos?.orgUnitCity || '',
            language,
            combinedContent,
            filterMetaData,
            hubCategoryName,
            isGroupHub
        )
    )

    React.useEffect(() => {
        const metaData = setMetaData(
            INVENTORY_TITLE_TYPES.H1,
            slug,
            vehicleCriteria,
            filters,
            dealerInfos?.orgUnitName,
            dealerInfos?.orgUnitCity,
            language,
            combinedContent,
            filterMetaData,
            hubCategoryName,
            isGroupHub
        )
        !isEmptyObject(filters) && setH1Content(metaData)
    }, [hubCategoryName, vehicleCriteria, filters])

    return (
        <Heading Tag='h1' subTitle={h1Content.subTitle}>
            {h1Content.mainTitle || h1Content.content || ''}
        </Heading>
    )
}

InventoryHeader.propTypes = {}

InventoryHeader.defaultProps = {}

export default InventoryHeader
