import React from 'react'
import PropTypes from 'prop-types'

import formatNumber from '../../../../../../utils/format-number.utils'
import { useTranslation } from '@sm360/hooks'
import clsx from 'clsx'

const Cashdown = ({ cashDown, extraClasses }) => {
    const { t } = useTranslation()

    const baseClass = 'vc-cashDown'
    const classNames = clsx(baseClass, extraClasses)

    const cashDownFormatted = formatNumber(cashDown, 'priceNoDigits')

    return <p className={classNames}>{t('paymentOptions.cashDown', { cashDownFormatted })}</p>
}

Cashdown.propTypes = {
    /** Cash Down */
    cashDown: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

Cashdown.defaultProps = {
    extraClasses: '',
    cashDown: '',
}

export default Cashdown
