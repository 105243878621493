import PropTypes from 'prop-types'
import React from 'react'

import ListingAlpha from '../../components/template/ListingAlpha.template'
import ListingBravo from '../../components/template/ListingBravo.template'

const ListingView = ({ packageVersion }) => {
    return (
        <div className='il-page'>
            {packageVersion === 'basic' && <ListingAlpha />}

            {packageVersion === 'premium' && <ListingBravo packageVersion={packageVersion} />}
        </div>
    )
}

ListingView.propTypes = {
    /** Package version to display */
    packageVersion: PropTypes.string,
}

ListingView.defaultProps = {
    packageVersion: '',
}
export default ListingView
