import { Record } from 'immutable'

export const FilterMetaDataModel = Record({
    code: '',
    count: 0,
    label: '',
    isSelected: false,
    class: '',
    group: '',
    url: '',
    colorHexValue: '',
    order: 0, // This will update once we get the configurable filter data
    includedItems: {},
    includedItemsValueKeys: [],
    type: '', // Use to indicate the filter option type: checkbox, range etc...,
    min: 0,
    max: 0,
    variant: '',
})
