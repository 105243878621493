import React from 'react'
import PropTypes from 'prop-types'

import Cashdown from '../common/cashdown/Cashdown'
import PaymentFrequency from '../common/payment-frequency/PaymentFrequency'
import PercentRate from '../common/percent-rate/PercentRate'
import FeesDisclaimer from '../common/fees-disclaimer/FeesDisclaimer'
import clsx from 'clsx'
import TaxesDisclaimer from '../common/taxes-disclaimer/TaxesDisclaimer'
import Incentives from '../common/incentives/Incentives'
import Term from '../common/term/Term'

const FinanceCard = ({ finance, disclaimer, extraClasses }) => {
    const { term, paymentFrequency, cashDown, incentives, taxesDisclaimer } = finance

    const baseClass = 'vc-financeCard'
    const classNames = clsx(baseClass, extraClasses)

    return (
        <div className={classNames}>
            <div className={`${baseClass}__price`}>
                <div className={`${baseClass}__frequencyTerm`}>
                    <PaymentFrequency termPayment={term.payment} frequency={paymentFrequency} />
                    <Term term={term?.term} />
                </div>
                <div className={`${baseClass}__rateCashDown`}>
                    <PercentRate rate={term.apr} term={term.term} />
                    <Cashdown cashDown={cashDown} />
                </div>
            </div>

            <Incentives extraClasses={`${baseClass}__incentives`} incentives={incentives} />
            <TaxesDisclaimer extraClasses={`${baseClass}__taxesDisclaimer`} taxesDisclaimer={taxesDisclaimer} />
            <FeesDisclaimer extraClasses={`${baseClass}__feesDisclaimer`} feesDisclaimer={disclaimer} />
        </div>
    )
}

FinanceCard.propTypes = {
    /** Finance object */
    finance: PropTypes.object,
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Disclaimer */
    disclaimer: PropTypes.string,
}

FinanceCard.defaultProps = {
    finance: {},
    extraClasses: '',
    disclaimer: '',
}

export default FinanceCard
