import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Button, Modal } from '@sm360/ui-library'
import { useTranslation } from '@sm360/hooks'

const FeesDisclaimer = ({ feesDisclaimer, extraClasses }) => {
    const baseClass = 'vc-feesDisclaimer'
    const classNames = clsx(baseClass, extraClasses)

    const { t } = useTranslation()

    if (!feesDisclaimer) return null

    return (
        <div className={classNames}>
            <Modal
                modalTitle=''
                className={`${baseClass}__modal`}
                trigger={<span className={`${baseClass}__trigger`}>*{t('common.offerDetails')}</span>}
                modalScopeCSS='vc-alpha'
            >
                {({ setClose }) => (
                    <div className={`${baseClass}__infos`}>
                        <Button closeButton onClick={setClose} />

                        <div>{feesDisclaimer}</div>
                    </div>
                )}
            </Modal>
        </div>
    )
}

FeesDisclaimer.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
    /** Fees Disclaimer */
    feesDisclaimer: PropTypes.string,
}

FeesDisclaimer.defaultProps = {
    feesDisclaimer: '',
}

export default FeesDisclaimer
