export const pageSortingDummies = {
    limit: {
        filterName: 'limit',
        values: [
            {
                value: '12',
                label: '12',
            },
            {
                value: '24',
                label: '24',
            },
            {
                value: '36',
                label: '36',
            },
            {
                value: '48',
                label: '48',
            },
            {
                value: '100',
                label: '100',
            },
        ],
        defaultValue: '12',
    },
}
