import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import formatNumber from './../../../../utils/format-number.utils'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'
import SpecsItem from './spec-item/SpecsItem'

const VehicleSpecs = ({ extraClasses }) => {
    const {
        state: {
            vehicle: { driveTrainDetails, fuel, transmissionDetails, odometer, newVehicle },
        },
    } = React.useContext(VehicleCardContext)

    const baseClass = 'vc-vehicleSpecs'
    const classNames = clsx(baseClass, extraClasses)

    if (!driveTrainDetails?.slug && !fuel?.name && !transmissionDetails?.name) {
        return null
    }

    return (
        <div className={classNames}>
            {driveTrainDetails?.slug && (
                <SpecsItem
                    extraClasses={`${baseClass}__item`}
                    label={driveTrainDetails?.slug.toUpperCase()}
                    icon='icon-specs-drivetrain'
                />
            )}

            {fuel?.name && <SpecsItem extraClasses={`${baseClass}__item`} label={fuel?.name} icon='icon-specs-fuel-type' />}

            {transmissionDetails?.name && (
                <SpecsItem extraClasses={`${baseClass}__item`} label={transmissionDetails?.name} icon='icon-specs-automatic' />
            )}

            {!newVehicle && odometer && (
                <SpecsItem
                    extraClasses={`${baseClass}__item`}
                    label={formatNumber(odometer, 'kilometer')}
                    icon='icon-specs-mileage'
                />
            )}
        </div>
    )
}

VehicleSpecs.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

VehicleSpecs.defaultProps = {
    extraClasses: '',
}

export default VehicleSpecs
