import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Img } from '@sm360/ui-library'
import { useTranslation, useSsr } from '@sm360/hooks'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

import { getImageUrl } from '../../../../utils/image-url-generator.utils'
import { generateVehicleFormUrl } from '../../../../utils/global-functions.utils'

const CarfaxBadges = ({ extraClasses }) => {
    const {
        state: {
            vehicle: { stockNo, make, model, year, carfaxBadges, newVehicle, carfaxIsActive },
            dealerInfos: { domain },
        },
    } = React.useContext(VehicleCardContext)

    const baseClass = 'vc-carfaxBadges'
    const classNames = clsx(baseClass, extraClasses)

    const { t } = useTranslation()

    const { isBrowser } = useSsr()

    const formUrl = t(`forms.27`)

    const vehicleFormUrl = generateVehicleFormUrl(make?.name, model?.name, year, stockNo, formUrl, isBrowser, domain)

    if (newVehicle || !carfaxIsActive) return <div className={classNames}></div>

    if (carfaxBadges !== null) {
        const { BadgesImageUrl, VhrReportUrl } = carfaxBadges || {}

        return (
            <a
                href={VhrReportUrl}
                className={classNames}
                data-carfax-version='badges'
                data-gtmsm360='ux__body-carfax'
                target='_blank'
                rel='noreferrer'
            >
                <Img alt='Carfax Badges' src={BadgesImageUrl} height='23' className={`${baseClass}__image`} />
            </a>
        )
    }

    return (
        <a href={vehicleFormUrl} className={classNames} data-carfax-version='form' data-gtmsm360='ux__body-carfax'>
            <Img alt='Logo Carfax' src={getImageUrl('/carproof/carproof-color-128.png', 'w75h23', 'manufacturer')} width='75' />
        </a>
    )
}

CarfaxBadges.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

CarfaxBadges.defaultProps = {
    extraClasses: '',
}

export default CarfaxBadges
