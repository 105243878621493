import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useTranslation } from '@sm360/hooks'
import FilterCategories from '../filter-categories/FilterCategories'
import FilterSearch from '../filter-search/FilterSearch'
import { Button } from '@sm360/ui-library'
import FilterSections from '../filter-sections/FilterSections'
import { ListingContext } from '../../configs/listing-view/state.manager'
import FilterLocations from '../filter-locations/FilterLocations'

const Filters = ({ extraClasses, isOpen, onClose }) => {
    const {
        state: { pagination },
    } = React.useContext(ListingContext)

    const { t } = useTranslation()

    const baseClass = 'il-filters'
    const classNames = clsx(baseClass, extraClasses)
    const filterSectionsRef = React.useRef()

    const totalNoOfItems = pagination?.numberOfItems || 0

    React.useEffect(() => {
        if (isOpen) {
            filterSectionsRef.current.scrollIntoView()
        }
    }, [isOpen])

    return (
        <div className={classNames}>
            <FilterSearch />
            <div className={`${baseClass}__sections ${isOpen ? 'open' : ''}`}>
                {/* scroll into view anchor */}
                <span ref={filterSectionsRef}></span>
                <Button className={`${baseClass}__closeButton`} label='x' onClick={onClose} />
                <FilterLocations />
                <FilterCategories />
                <FilterSections />
                <div className={`${baseClass}__footer`}>
                    <Button
                        className={`${baseClass}__footerButton`}
                        label={t('filters.footerButton', { totalNoOfItems })}
                        displayAsBlock
                        onClick={onClose}
                    />
                </div>
            </div>
        </div>
    )
}

Filters.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Check if filters are opened on sr devices */
    isOpen: PropTypes.bool,
    /** Close action for sr devices */
    onClose: PropTypes.func,
}

Filters.defaultProps = {
    extraClasses: '',
    isOpen: false,
    onClose: () => {},
}

export default Filters
