import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import formatNumber from '../../../../../../utils/format-number.utils'

import { useTranslation } from '@sm360/hooks'

const Price = ({ sellingPrice, retailDetailsTotal, showTitle, showRetailsTotal, extraClasses }) => {
    const { t } = useTranslation()

    const baseClass = 'vc-price'
    const classNames = clsx(baseClass, extraClasses)

    if (!sellingPrice && !retailDetailsTotal) return null

    return (
        <div className={classNames}>
            {showTitle && <p className={`${baseClass}__title`}>{t('paymentOptions.sellingPrice')}</p>}
            <div className={`${baseClass}__wrapper`}>
                {retailDetailsTotal !== sellingPrice && showRetailsTotal && (
                    <del className={`${baseClass}__retailDetails`}>{formatNumber(retailDetailsTotal, 'priceNoDigitsCeil')}</del>
                )}
                <span className={`${baseClass}__sellingPrice`}>{formatNumber(sellingPrice, 'priceNoDigitsCeil')}</span>
            </div>
        </div>
    )
}

Price.propTypes = {
    /** Selling Price */
    sellingPrice: PropTypes.number,
    /** Retails Details Total */
    retailDetailsTotal: PropTypes.number,
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Show Selling Price Title */
    showTitle: PropTypes.bool,
    /** Show Retails Total */
    showRetailsTotal: PropTypes.bool,
}

Price.defaultProps = {
    extraClasses: '',
    sellingPrice: 0,
    retailDetailsTotal: 0,
    showTitle: true,
    showRetailsTotal: true,
}

export default Price
