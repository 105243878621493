import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ChainedBackend from 'i18next-chained-backend'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { getTranslationsAPIUrl } from '../../apis/showroom360/showroom360.service'
import { runtimeConfig } from '../../widget-ssr/config/runtimeConfig'

const backendOptions = {
    loadPath: '{{lng}}|{{ns}}',
    request: (options, url, payload, callback) => {
        try {
            const [lng] = url.split('|')
            const dummyData = {
                'common.home': 'Home',
                'common.offerDetails': 'Offer Details',
                'forms.27': '/en/form/inventory/carfax-request/27',
                heading: '{{hubName}} Listing',
                'filters.categories': 'Categories',
                'filters.filter': 'Filter',
                'filters.footerButton': '{{totalNoOfItems}} Vehicles found',
                'filters.max': 'Max.',
                'filters.min': 'Min.',
                'filters.other': 'Other1',
                'filters.pageSorting.labelBefore': 'and display',
                'filters.pageSorting.labelAfter': 'per page',
                'filters.search.title': 'Find your perfect vehicle',
                'filters.search.label': 'Make, model, year?',
                'filters.reset.city': 'City',
                'filters.reset.doors': 'Doors',
                'filters.reset.driveTrainId': 'Drive Train',
                'filters.reset.engineDescriptionsId': 'Engine Description',
                'filters.reset.engineId': 'Engine',
                'filters.reset.exteriorColorId': 'Exterior Color',
                'filters.reset.frameCategoryId': 'Frame Category',
                'filters.reset.frameStyleId': 'Frame Style',
                'filters.reset.fuelId': 'Fuel',
                'filters.reset.interiorColorId': 'Interior1 Color',
                'filters.reset.inventoryId': 'Inventory',
                'filters.reset.makeId': 'Make',
                'filters.reset.makeIdAndModelId': 'Make & Model',
                'filters.reset.modelId': 'Model',
                'filters.reset.odometerBuckets': 'Odometer Buckets',
                'filters.reset.odometerRange': 'Odometer Range',
                'filters.reset.odometerRangeAll': 'Odometer Range All',
                'filters.reset.passengers': 'Passengers',
                'filters.reset.priceBuckets': 'Price Buckets',
                'filters.reset.priceRange': 'Price Range',
                'filters.reset.priceRangeAll': 'Price Range All',
                'filters.reset.primaryOrganizationUnitId': 'Primary OrganizationUnit',
                'filters.reset.provinceId': 'Province',
                'filters.reset.transmissionId': 'Transmission',
                'filters.reset.trimId': 'Trim',
                'filters.reset.vehicleCategoryId': 'Vehicle Category',
                'filters.reset.year': 'Year',
                'filters.reset.yearRange': 'Year Range',
                'filters.reset.yearRangeAll': 'Year Range All',
                'filters.sortedBy': 'Sorted by',
                'filters.vehicleFound': '<0>{{totalVehicles}}</0> <1>vehicle found</1>',
                'filters.vehiclesFound': '<0>{{totalVehicles}}</0> <1>vehicless found</1>',
                'tmb.tabTitle.cash': 'Cash',
                'tmb.tabTitle.finance': 'Finance',
                'tmb.tabTitle.lease': 'Lease',
                'vehicle.actions.details.title': 'See Details of {{year}} {{make}} {{model}}',
                'vehicle.actions.details.label': 'See Details',
                'vehicle.availableAt': '<0>Available at</0> <1>{{name}}</1>',
                'vehicle.callForPricing': 'Call For Price',
                'vehicle.details.new': '/en/new-inventory/{{make}}/{{model}}/{{year}}-{{make}}-{{model}}-id{{vehicleId}}',
                'vehicle.details.used': '/en/used-inventory/{{make}}/{{model}}/{{year}}-{{make}}-{{model}}-id{{vehicleId}}',
                'vehicle.name.yearMake': '{{year}} {{make}}',
                'paymentOptions.cashDown': 'with {{cashDownFormatted}} down payment',
                'paymentOptions.frequency.12': '/monthly',
                'paymentOptions.frequency.24': '/semi-monthly',
                'paymentOptions.frequency.26': '/bi-weekly',
                'paymentOptions.frequency.52': '/week',
                'paymentOptions.incentives.dealer': 'Dealer Discount',
                'paymentOptions.incentives.government': 'EV Rebate',
                'paymentOptions.incentives.manufacturer': 'OEM Rebate',
                'paymentOptions.sellingPrice': 'Selling Price',
                'paymentOptions.termMonths': 'For {{term}} months',
                'paymentOptions.totalRebates': 'Save Up To {{price}}',
                'filters.reset.clear': 'Clear',
                'filters.reset.text': 'Search',
                'filters.reset.maxPrice': 'Max Price',
                'fitlers.reset.minPrice': 'Min Price',
                'filters.reset.minPrice': 'Min Price',
                'filters.locations': 'Locations',
                'vehicle.details.group': '/en/{{hub}}/{{make}}/{{model}}/{{year}}-{{make}}-{{model}}-id{{vehicleId}}',
                'vehicle.details.group.new':
                    '/en/group-new-inventory/{{make}}/{{model}}/{{year}}-{{make}}-{{model}}-id{{vehicleId}}',
                'vehicle.details.group.used':
                    '/en/group-used-inventory/{{make}}/{{model}}/{{year}}-{{make}}-{{model}}-id{{vehicleId}}',
                'filters.locations.all': 'All of Humberview Group',
                'filters.reset.minYear': 'Min Year',
                'filters.reset.maxYear': 'Max Year',
                'filters.reset.minOdometer': 'Min Odometer',
                'filters.reset.maxOdometer': 'Max Odometer',
                'pc.sellingPrice': 'Selling Price',
                'pc.tradeInEstimate': 'Trade-in Estimate',
                'pc.lien': 'Amount Owning on Trade-in',
                'pc.targetPaymentAmount': 'Target Payment Amount',
                'pc.rate': 'Rate',
                'pc.term': 'Term',
                'pc.payment': 'Payment',
                'pc.months': 'months',
                'pc.cash': 'Cash',
                'pc.finance': 'Finance',
                'pc.lease': 'Lease',
                'pc.paymentFrequency': 'Payment Frequency',
                'pc.cashdown': 'Cashdown',
                'pc.kmPerYearPlan': 'Kilometer Plan',
                'pc.configureMyPayment': 'Configure Your Payment',
                'pc.updatePaymentOptions': 'Update Payment Options',
                'pc.addToGarage': 'Add to Garage',
                'pc.buyOnline': 'Buy Online',
                'pc.offerDetails': 'Offer details',
                'pc.byPaymentFrequency.12': '/ monthly',
                'pc.byPaymentFrequency.24': '/ semi-monthly',
                'pc.byPaymentFrequency.26': '/ bi-weekly',
                'pc.byPaymentFrequency.52': '/ week',
                'pc.termByMonths': '{{term}} months',
                'pc.termPayment.priceDisclaimer': '{{payment}} $ + {{paymentTaxes}} $ ({{taxesDetail}}) = {{totalPayment}} $',
                'pc.paymentFrequency.12': 'Monthly',
                'pc.paymentFrequency.24': 'Semy-Monthly',
                'pc.paymentFrequency.26': 'Bi-Weekly',
                'pc.paymentFrequency.52': 'Weekly',
                'pc.checkoutUrl': '/en/checkout?quoteId={{quoteId}}',
            }
            if (runtimeConfig.REACT_APP_DOMAIN) {
                callback(null, {
                    data: dummyData,
                    status: 200,
                })
            } else {
                getTranslationsAPIUrl(lng).then((response) => {
                    callback(null, {
                        data: response || dummyData,
                        status: 200,
                    })
                })
            }
        } catch (e) {
            callback(null, {
                status: 500,
            })
        }
    },
}

i18n.use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['path'],
        },
        // preload: ['en', 'fr'],
        nonExplicitSupportedLngs: true,
        load: 'languageOnly',
        fallbackLng: 'en',
        backend: {
            backends: [Backend],
            backendOptions: [backendOptions],
        },
        ns: ['translations', 'defaultTranslations'],
        defaultNS: 'translations',
        fallbackNS: 'defaultTranslations',
        debug: process.env.NODE_ENV === 'development',
        react: {
            useSuspense: true,
        },
    })

export default i18n
