import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from '@sm360/hooks'

import { ListingContext } from '../../configs/listing-view/state.manager'
import { getInventoryTitle, getHubCategory } from '../../utils/global-functions.utils'
import { INVENTORY_TITLE_TYPES } from '../../constants/inventoryTitleType'
import { FILTER_GROUP_URL } from '../../constants/filterOptions'

const SeoContent = (extraClasses) => {
    const {
        state: {
            hubName,
            vehicleCriteria,
            filters,
            seoContent,
            dealerInfos: { orgUnitName, orgUnitCity },
            filterMetaData,
            hubCategories,
        },
    } = React.useContext(ListingContext)

    const classNames = clsx('il-seo-content wysiwyg', extraClasses)

    const {
        i18n: { language },
    } = useTranslation()

    const { name: hubCategoryName, vehicleInventoryHubCategory } = getHubCategory(hubCategories, hubName, language) || ''
    const slug = vehicleInventoryHubCategory && vehicleInventoryHubCategory.slug.toLowerCase()
    const isGroupHub = hubName.search(`${FILTER_GROUP_URL[language]}`) >= 0

    const { content } = getInventoryTitle(
        INVENTORY_TITLE_TYPES.SEO,
        slug,
        vehicleCriteria,
        filters,
        orgUnitName,
        orgUnitCity,
        language,
        seoContent,
        filterMetaData,
        hubCategoryName,
        isGroupHub
    )

    return (
        content && (
            <div
                className={classNames}
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            ></div>
        )
    )
}

SeoContent.propTypes = {
    extraClasses: PropTypes.string,
}

SeoContent.defaultProps = {
    extraClasses: '',
}

export default SeoContent
