/**
 * Format number.
 * @param { String || Number } value - Value.
 * @param { String } type - Type of format.
 * @param { String } [lang] - Language
 * @return { String || Number }
 */
const formatNumberUtils = (value, type, lang) => {
    const locales = lang === 'fr' ? 'fr-CA' : 'en-CA'

    switch (type) {
        case 'kilometer': {
            const formatter = new Intl.NumberFormat(locales, {
                minimumFractionDigits: 0,
            })

            return `${formatter.format(value)} km`
        }
        case 'percent': {
            const formatter = new Intl.NumberFormat(locales, {
                style: 'percent',
                minimumFractionDigits: 2,
            })

            return `${formatter.format(value / 100)}`
        }
        case 'price': {
            const formatter = new Intl.NumberFormat(locales, {
                style: 'currency',
                currency: 'CAD',
                minimumFractionDigits: 2,
            })

            return formatter.format(value).replace('CA', '')
        }
        case 'priceDigitsAuto': {
            const formatter = new Intl.NumberFormat(locales, {
                style: 'currency',
                currency: 'CAD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
            })

            return formatter.format(value).replace('CA', '')
        }
        case 'priceNoDigits': {
            const formatter = new Intl.NumberFormat(locales, {
                style: 'currency',
                currency: 'CAD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            })

            return formatter.format(value).replace('CA', '')
        }
        case 'priceNoDigitsCeil': {
            const formatter = new Intl.NumberFormat(locales, {
                style: 'currency',
                currency: 'CAD',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                roundingMode: 'ceil',
            })

            return formatter.format(value).replace('CA', '')
        }
        case 'number': {
            const formatter = new Intl.NumberFormat(locales, {
                minimumFractionDigits: 0,
            })

            return formatter.format(value)
        }
        case 'date': {
            const date = new Date(value)
            const options = { year: 'numeric', month: 'long', day: 'numeric' }

            return date.toLocaleDateString(locales, options)
        }
        default:
            return value
    }
}

export default formatNumberUtils
