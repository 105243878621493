import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useTranslation } from '@sm360/hooks'

const Term = ({ term, extraClasses }) => {
    const { t } = useTranslation()

    const baseClass = 'vc-term'
    const classNames = clsx(baseClass, extraClasses)

    return <p className={classNames}>{t('paymentOptions.termMonths', { term })}</p>
}

Term.propTypes = {
    /** Term Payment by frequency */
    term: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

Term.defaultProps = {
    extraClasses: '',
    term: '',
}

export default Term
