import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import formatNumber from '../../../../../../utils/format-number.utils'
import { useTranslation } from '@sm360/hooks'

const PaymentFrequency = ({ termPayment, frequency, extraClasses }) => {
    const { t } = useTranslation()

    const baseClass = 'vc-paymentFrequency'
    const classNames = clsx(baseClass, extraClasses)

    return (
        <div className={classNames}>
            <span className={`${baseClass}__price`}>{formatNumber(termPayment, 'priceNoDigitsCeil')}</span>
            <span className={`${baseClass}__frequency`}>{t(`paymentOptions.frequency.${frequency}`)}</span>
        </div>
    )
}

PaymentFrequency.propTypes = {
    /** Term Payment by frequency */
    termPayment: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    /** Frequency of payments */
    frequency: PropTypes.oneOf([12, 24, 26, 52, '12', '24', '26', '52']).isRequired,
    /** Fees Disclaimer */
    feesDisclaimer: PropTypes.string,
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

PaymentFrequency.defaultProps = {
    extraClasses: '',
    termPayment: '',
    frequency: '52',
    feesDisclaimer: '',
}

export default PaymentFrequency
