import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { ListingContext } from '../../configs/listing-view/state.manager'
import { useTranslation } from '@sm360/hooks'
import Heading from '../heading/Heading'
import { Button, Input } from '@sm360/ui-library'
import { changeUrl } from '../../utils/handle-url.utils'
import ga4Trigger from '../../datalayer/analytics.service'
import dataLayerTags from '../../constants/dataLayerTags'
import { getPathname } from '../../utils/helper.utils'

const FilterSearch = ({ extraClasses }) => {
    const {
        state: {
            filterMetaData,
            dealerInfos: { lang },
            vehicleCriteria,
            hubName,
        },
        addVehicleCriteria,
        dispatch,
    } = React.useContext(ListingContext)
    const { t } = useTranslation()
    const baseClass = 'il-filterSearch'
    const classNames = clsx(baseClass, extraClasses)

    let typingTimer

    const triggerAnalytics = (value) => {
        ga4Trigger(dataLayerTags.LAYER_NAVIGATION__FILTERS(getPathname(), 'searchbar'))
        ga4Trigger(dataLayerTags.FILTER_SEARCH(value))
    }

    const handleInputSearch = (value) => {
        const searchCriteria = { text: value }
        const criteria = addVehicleCriteria(vehicleCriteria, searchCriteria, null, true)
        changeUrl(criteria, filterMetaData, lang, hubName)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
        triggerAnalytics(value)
    }

    const handleChange = (value) => {
        const doneTypingInterval = value ? 1000 : 300
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => handleInputSearch(value), doneTypingInterval)
    }

    return (
        <div className={classNames}>
            <Heading extraClasses={`${baseClass}__heading heading3`} Tag='p' variant='heading3'>
                {t('filters.search.title')}
            </Heading>
            <div className={`${baseClass}__inputGroup`}>
                <Input
                    className={`${baseClass}__input`}
                    clearable
                    placeholder={t('filters.search.label')}
                    maxChar={30}
                    type='search'
                    onChange={(value) => handleChange(value)}
                    value={vehicleCriteria?.text}
                />
                <Button icon='search' className={`${baseClass}__button`} />
            </div>
        </div>
    )
}

FilterSearch.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

FilterSearch.defaultProps = {
    extraClasses: '',
}

export default FilterSearch
