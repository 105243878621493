/**
 * Moto Insight
 * @returns {function(): void}
 * @constructor
 */
export const motoInsight = () => {
    const timer = setTimeout(() => {
        window.MOTOINSIGHT?.updateView()
    }, 100)
    return () => clearTimeout(timer)
}

/**
 * Shop Click Drive
 * @param { String } makeSlug - Vehicle Make Slug
 * @param { String } dealerBac - Dealer Business Associate Account (BAC)
 * @param { String } serialNo -  Vehicle Serial Number
 * @param { String } lang - Website Language
 * @returns { string }
 */
export const shopClickDrive = (makeSlug, dealerBac, serialNo, lang) => {
    let makeShopClickDriveCode = ''

    switch (makeSlug) {
        case 'buick':
            makeShopClickDriveCode = 11
            break
        case 'cadillac':
            makeShopClickDriveCode = 12
            break
        case 'chevrolet':
            makeShopClickDriveCode = 13
            break
        case 'gmc':
            makeShopClickDriveCode = 48
            break
        default:
            break
    }

    if (!makeShopClickDriveCode) return ''

    return `https://ca.buy.gm.com/${dealerBac}/${makeShopClickDriveCode}/${serialNo}${lang === 'fr' ? '?l=fr' : ''}`
}
