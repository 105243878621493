import { WindowProxy, isArray, isFunction, isNumber, isObject, isString } from '../utils/helper.utils'
/**
 * Verify if Google Tag Manager is configured in the website
 * @returns {boolean} is available or not
 */
const isAnalyticsAvailable = () => {
    return WindowProxy && WindowProxy.dataLayer && isArray(WindowProxy.dataLayer) && isFunction(WindowProxy.dataLayer.push)
}
// function to trigger ga4 with custom tags
const ga4Trigger = ({ category, label, ...customTags }) => {
    const customTagsArray = Object.values(customTags)
    const paramsArray = [category, ...customTagsArray]
    if (label) paramsArray.push(label)

    const areParamsValid = paramsArray.every((value) => isString(value) || isNumber(value))
    if (isAnalyticsAvailable() && areParamsValid) {
        const tagsObject = {
            event: 'gaTriggerEvent',
            gaEventCategory: category,
            gaEventLabel: label,
            ...customTags,
        }

        if (!label) delete tagsObject.gaEventLabel

        WindowProxy.dataLayer.push(tagsObject)
    }
}

export default ga4Trigger
