import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useTranslation } from '@sm360/hooks'
import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

const VehicleName = ({ extraClasses }) => {
    const {
        state: {
            vehicle: { year, make, model, trim },
        },
    } = React.useContext(VehicleCardContext)

    const baseClass = 'vc-vehicleName'
    const classNames = clsx(baseClass, extraClasses)

    const { t } = useTranslation()

    return (
        <div className={classNames}>
            {year && make?.name && (
                <p className={`${baseClass}__yearMake`}>{t('vehicle.name.yearMake', { year, make: make?.name })}</p>
            )}
            {model?.name && <p className={`${baseClass}__model`}>{model?.name}</p>}
            {trim?.name && <p className={`${baseClass}__trim`}>{trim?.name}</p>}
        </div>
    )
}

VehicleName.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

VehicleName.defaultProps = {
    extraClasses: '',
}

export default VehicleName
