import axios from 'axios'

import { axiosErrorHandler, buildUrlWithParameters } from '../../utils/handle-url.utils'

/**
 * Get Inventory Promotions
 * @param { String } lang - Language
 * @param { Object } params - Params of promo - ex: location | trimId | limit
 * @param { String } [params.location] - Location
 * @param { String | Number } [params.trimId] - Trim Id
 * @param { String | Number } [params.limit] - Limit
 * @returns {Promise<{}>}
 */
export const getInventoryPromotionsApiUrl = async (lang, params, domain) => {
    let apiResponse = {}
    const apiUrl = `/${lang}/promotion/fragments/api/find`
    const config = {
        headers: {
            'accept-language': `${lang}-CA`,
            authorization: 'Basic c20zNjA6c20zNjA=',
        },
    }

    const url = domain || window?.web_domain
    const urlComplete = `${url.concat(apiUrl)}`

    const urlWithParams = buildUrlWithParameters(urlComplete, params)

    const response = await axios.get(urlWithParams, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data
    }
    return apiResponse
}
