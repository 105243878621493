import { apiUrlEnvUtils } from './api-url-env.utils'

const IMAGESERVER_URL = apiUrlEnvUtils('REACT_APP_IMAGESERVER_URL')

/**
 * Generates the Image Url
 * @param { String } imgUrl - Device type: (mobile, tablet, desktop)
 * @param { String } imageSize - Server side image size. Example: w640h480
 * @param { String } imgType - Image type: (promo, web, hubCategory, inventory, newcar, manufacturer, article)
 */
export const getImageUrl = (imgUrl, imageSize, imgType) => {
    const imageSizeUrl = imageSize ? `/ir/${imageSize}` : ''
    const imageTypeUrl = imgType ? `/images/${imgType}` : ''

    if (imgUrl && imgUrl.includes('https://')) return imgUrl
    else if (imgUrl) return `${IMAGESERVER_URL}${imageSizeUrl}${imageTypeUrl}${imgUrl}`
    else return null
}
