import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Icon } from '@sm360/ui-library'
import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

const RecentViews = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            vehicle: { pageViewSummary },
        },
    } = React.useContext(VehicleCardContext)

    const { recentViewCount } = pageViewSummary || {}

    const baseClass = 'vc-recentViews'
    const classNames = clsx(baseClass, extraClasses)

    if (recentViewCount && recentViewCount >= 10) {
        return (
            <div className={classNames} {...extraParams}>
                <Icon name='icon-eye' className={`${baseClass}__icon`} />

                <span className={`${baseClass}__count`}>{recentViewCount}</span>
            </div>
        )
    }

    return null
}

RecentViews.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

RecentViews.defaultProps = {
    extraClasses: '',
}

export default RecentViews
