import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { InputNumber } from '@sm360/ui-library'
import { useTranslation } from '@sm360/hooks'

const InputRange = ({ extraClasses, minInput, maxInput, vehicleCriteria, handleInputRange, min, max }) => {
    const baseClass = 'input-range'
    const classNames = clsx(baseClass, extraClasses)
    const { t } = useTranslation()
    const [minValue, setMinValue] = useState(min)
    const [maxValue, setMaxValue] = useState(max)

    useEffect(() => {
        setMinValue(vehicleCriteria[minInput] || min)
        setMaxValue(vehicleCriteria[maxInput] || max)
    }, [min, max, vehicleCriteria])

    const onMinInputChange = (value) => {
        if (parseInt(value) !== minValue) {
            setMinValue(value)
            handleInputRange(minInput, value < min || value > max ? min : value)
        }
    }

    const onMaxInputChange = (value) => {
        if (parseInt(value) !== maxValue) {
            setMaxValue(value)
            handleInputRange(maxInput, value > max || value < min ? max : value)
        }
    }

    const getRangeValue = (attribute) => {
        if (attribute === minInput) {
            return minValue
        } else {
            return maxValue
        }
    }
    return (
        <div className={classNames}>
            <InputNumber
                label={t('filters.min')}
                placeholder='0'
                onBlur={(v) => onMinInputChange(v.target.value)}
                value={getRangeValue(minInput)}
            />
            <InputNumber
                label={t('filters.max')}
                placeholder='100000'
                onBlur={(v) => onMaxInputChange(v.target.value)}
                value={getRangeValue(maxInput)}
            />
        </div>
    )
}

InputRange.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
    /** Selected vehicle criteria obj */
    vehicleCriteria: PropTypes.object,
    /** Minimum input textbox label */
    minInput: PropTypes.string,
    /** Maximum input textbox label */
    maxInput: PropTypes.string,
    /** Change function mainly for filters except input range */
    handleChange: PropTypes.func,
    /** Change function only for Input range */
    handleInputRange: PropTypes.func,
    /** Number value for input range minimum */
    min: PropTypes.number,
    /** Number value for input range maximum */
    max: PropTypes.number,
}

InputRange.defaultProps = {
    extraClasses: '',
    min: 0,
    max: 0,
}

export default InputRange
