import { isBoolean, isObject } from './helper.utils'
import { FILTER_GROUP_URL, FILTER_LOCATIONS_EXCLUDE, FILTER_OPTIONS_REMOVED_FROM_URL } from '../constants/filterOptions'

/**
 * Handles axios errors and console log them if in local development.
 * @param { Object } error - An axios error object.
 */
export const axiosErrorHandler = (error) => {
    if (process.env.NODE_ENV === 'development') {
        console.log('API Error:', error?.response?.config?.url, error?.response?.status)
    }
}

/**
 * Take an url and append the given parameters.
 * @param { String } url - An url.
 * @param { Object } parameters - An object containing the parameters to add to the url.
 * @return { String }
 */
export const buildUrlWithParameters = (url, parameters) => {
    try {
        const modifiedUrl = new URL(url)
        modifiedUrl.search = new URLSearchParams(parameters).toString()

        return modifiedUrl.toString()
    } catch (error) {
        return ''
    }
}

/**
 * Take an url with parameters and return the parameters as an object.
 * @param { URL } url - A string containing an url.
 * @return { Object }
 */
export const extractUrlParametersAsObject = (url = window.location) => {
    const urlParameters = new URLSearchParams(url.search)
    return Object.fromEntries(urlParameters)
}

/**
 * Take a path url and return each information.
 * @return { Object }
 */
export const extractPathUrlInfoAsObject = (location) => {
    const [lang = '', inventory = '', makeName = '', modelName = ''] =
        typeof window !== 'undefined'
            ? window.location.pathname.split('/').slice(1, 5)
            : location && location.split('/').slice(1, 5)

    const langUrl = `/${lang}`
    const inventoryUrl = `/${lang}/${inventory}`
    const makeUrl = makeName ? `/${lang}/${inventory}/${makeName}` : ''
    const modelUrl = modelName ? `/${lang}/${inventory}/${makeName}/${modelName}` : ''

    return { lang, inventory, makeName, modelName, langUrl, inventoryUrl, makeUrl, modelUrl }
}

/**
 * Get the hub path name.
 * @return { String }
 */
export const getHubPathName = (lang, location, orgUnitId) => {
    let pathname = ''
    if (typeof window !== 'undefined' && window.location.pathname !== '/') {
        pathname = window.location.pathname.split('/')[2]
    } else {
        pathname = location.split('/')[2]
    }
    pathname = lang === 'fr' ? pathname?.replace('inventaire-', '') : pathname?.replace('-inventory', '')

    return getFinalHubName(pathname, lang, orgUnitId)
}

/**
 * Update page url with new parameters using an object filled with parameters.
 * @param { Object } parameters - An object filled with parameters.
 */
export const updatePageUrl = (parameters) => {
    const urlWithParameters = buildUrlWithParameters(window.location, { ...parameters })

    window.history.replaceState(null, '', urlWithParameters)
}

/**
 * Change the URL with provided hub name
 * @param {string} lang
 * @param {string} hubName
 * @returns Redirection URL
 */
export const changeUrlByHubName = (lang, hubName) => {
    const url = lang === 'fr' ? `/${lang}/inventaire-${hubName}` : `/${lang}/${hubName}-inventory`
    return window.location.assign(url)
}

/** Change the URL based on Filter selections
 * @param {*} vehicleCriteria
 * @returns
 */
export const changeUrl = (vehicleCriteria, filterMetaData, lang, hubName, clearParams = false) => {
    if (typeof window !== 'undefined') {
        const modifiedUrl = buildUrl(vehicleCriteria, filterMetaData, lang, hubName)
        const updatedCriteria = updateCriteriaForURL(vehicleCriteria)

        const queryParams = !clearParams ? createQueryParams(updatedCriteria) : ''

        const url = new URL(modifiedUrl, window.location.origin).href
        return window.history.replaceState({}, '', `${url}${queryParams}`)
    }
    return false
}

/**
 * @param {Object} vehicleCriteria
 * @returns Object with the URL params
 */
const updateCriteriaForURL = (vehicleCriteria) => {
    let updatedCriteria = {}
    Object.entries(vehicleCriteria).forEach(([key, value]) => {
        // Not to return criteria if there is only make is selected
        if (key === 'makeId' && value.length === 1) {
            return undefined
        } else if (
            // Not to return criteria if only one make and one model is selected
            key === 'modelId' &&
            value.length === 1 &&
            Object.keys(vehicleCriteria).includes('makeId') &&
            vehicleCriteria.makeId.length === 1
        ) {
            return undefined
        } else if (!FILTER_OPTIONS_REMOVED_FROM_URL.includes(key)) {
            updatedCriteria = { ...updatedCriteria, ...{ [key]: value } }
        }
        return updatedCriteria
    })
    return updatedCriteria
}

/**
 * Create query params with the selected vehicle criteria
 * @param {*} vehicleCriteria
 * @returns formated query string with all vehicle criteria params
 */

export const createQueryParams = (vehicleCriteria) => {
    let queryParamsString = ''
    const queryParams = extractUrlParametersAsObject() // Need to confirm if this one would provide the required or not
    if (!vehicleCriteria) {
        return queryParamsString
    }

    Object.entries(vehicleCriteria).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            if (value.length > 0) {
                queryParams[key] = value.join(',')
            } else {
                queryParams[key] = ''
            }
        } else if (isObject(value)) {
            const formattedKey = key.split('Range')[0]
            queryParams[`${formattedKey}From`] = value === '' ? '' : value.from
            queryParams[`${formattedKey}To`] = value === '' ? '' : value.to
        } else if (!isObject(value) && !isBoolean(value)) {
            queryParams[key] = value
        }
    })

    Object.entries(queryParams).forEach(([paramName, paramValue]) => {
        const paramSign = !queryParamsString ? '?' : '&'
        if (paramValue && vehicleCriteria[paramName]) queryParamsString += `${paramSign}${paramName}=${paramValue}`
    })

    return queryParamsString
}

/**
 *
 * @param {string} url
 * @param {string} param
 * @param {string/Int} value
 * @returns URL with value
 */
const replaceParamWithValue = (url, param, value) => {
    return url.replace(
        new RegExp(`(${param})`, 'g'),
        value || value === 0 ? encodeURIComponent(String(value).replace('/', '')) : value
    )
}

/**
 *
 * @param {Array} filterMetaData
 * @param {string} attribute
 * @returns Filtered metadatas object for provided attribute
 */
const getFilters = (filterMetaData, attribute) => {
    return filterMetaData?.find((metadata) => {
        return metadata.name === attribute
    })?.metadatas
}

export const buildInventoryUrl = (lang, hubName) => {
    const isGroupHub = hubName.search('group') >= 0
    const localizedGroup = FILTER_GROUP_URL[lang]
    const groupUrl = isGroupHub ? localizedGroup : ''
    const newhubName = hubName.replace(localizedGroup, '')
    return lang === 'fr' ? `${groupUrl}inventaire-${newhubName}` : `${groupUrl}${newhubName}-inventory`
}

/**
 *
 * @param {Object} vehicleCriteria
 * @param {Array} filterMetaData
 * @param {string} lang
 * @param {string} hubName
 * @returns Provide the url by filling make and model name if necessory
 */
export const buildUrl = (vehicleCriteria, filterMetaData, lang, hubName) => {
    let url = `/${lang}/${buildInventoryUrl(lang, hubName)}/{make}/{model}`
    // For Make
    if (vehicleCriteria && vehicleCriteria.makeId && vehicleCriteria.makeId.length === 1) {
        const makeId = parseInt(vehicleCriteria.makeId[0], 10)
        const makes = getFilters(filterMetaData, 'makeId')
        const selectedMake = makes.find((make) => {
            return make.code === makeId
        }).label
        url = replaceParamWithValue(url, '{make}', selectedMake.toLowerCase())
    } else {
        url = replaceParamWithValue(url, '/{make}', '')
    }

    // For Model
    if (
        vehicleCriteria &&
        vehicleCriteria.makeId &&
        vehicleCriteria.makeId.length === 1 &&
        vehicleCriteria.modelId &&
        vehicleCriteria.modelId.length === 1
    ) {
        const modelId = parseInt(vehicleCriteria.modelId[0], 10)
        const models = getFilters(filterMetaData, 'modelId')
        const selectedModel = models.find((model) => {
            return model.code === modelId
        }).label
        url = replaceParamWithValue(url, '{model}', selectedModel.toLowerCase())
    } else {
        url = replaceParamWithValue(url, '/{model}', '')
    }
    return url
}

/**
 * Returns true if given url is external
 * @param {String} url
 * @returns { Boolean }
 */
export const isExternalUrl = (url) => url?.startsWith('https')

/**
 * Define the canonical url of the document.
 */
export const handleCanonicalUrl = (lang) => {
    let listingUrl = window.location.href.split('?')[0]
    listingUrl = listingUrl.replace(FILTER_GROUP_URL[lang], '')

    const canonical = document.querySelector('link[rel="canonical"]')
    if (canonical) {
        canonical.href = listingUrl
    } else {
        const canonical = document.createElement('link')
        canonical.rel = 'canonical'
        canonical.href = listingUrl
        document.head.appendChild(canonical)
    }
}

/**
 * Find current selected inventory page based on the hub categories.
 */
export const findSelectedHubCategory = (hubName, hubCategories, lang) => {
    let urlMapping = hubName
    const isGroupHub = hubName.search(`${FILTER_GROUP_URL[lang]}`) >= 0
    if (isGroupHub) {
        urlMapping = hubName.replace(`${FILTER_GROUP_URL[lang]}`, '')
    }
    if (hubCategories && hubCategories.length > 0) {
        const hubCategory = hubCategories?.find((category) => category.urlMapping === urlMapping)
        return hubCategory || undefined
    }
    return undefined
}

/**
 * Check if current hubName is in the blacklist to not
 * display the locations filter (fused inventory)
 */
export const hideLocationsFilter = (hubName, lang, orgUnitId) => {
    const isGroupHub = hubName.search(`${FILTER_GROUP_URL[lang]}`) >= 0
    const excludeOrganization = FILTER_LOCATIONS_EXCLUDE.includes(orgUnitId)
    return excludeOrganization && isGroupHub
}

/**
 * Removes group- from hubName if the orgUnitId is in the blacklist
 * for the locations filter and redirect.
 */
export const getFinalHubName = (initialHubName, lang, orgUnitId) => {
    let hubName = initialHubName
    if (!lang || !orgUnitId) return hubName

    const hideLocations = hideLocationsFilter(hubName, lang, orgUnitId)

    // if fused inventory is not allowed, revert hub name
    // to regular inventory and redirect url to match
    if (hideLocations) {
        hubName = hubName.replace(`${FILTER_GROUP_URL[lang]}`, '')
        changeUrl({}, [], lang, hubName, true)
    }
    return hubName
}
