import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Expander, Icon } from '@sm360/ui-library'

const FilterSection = ({ children, isOpen: initialIsOpen, label, extraClasses }) => {
    const baseClass = 'il-filterSection'
    const classNames = clsx(baseClass, extraClasses)
    const [isOpen, setIsOpen] = useState(initialIsOpen)
    return (
        <Expander
            trigger={
                <div className={`${baseClass}__header`}>
                    <span className={`${baseClass}__label`}>{label}</span>
                    <Icon name={`${isOpen ? 'icon-accordion-close' : 'icon-accordion-open'}`} className={`${baseClass}__icon`} />
                </div>
            }
            className={classNames}
            isOpen={isOpen}
            iconPosition='hide'
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className={`${baseClass}__content`}>{children}</div>
        </Expander>
    )
}

FilterSection.propTypes = {
    /** Filter section label */
    label: PropTypes.string,
    /** Expander open/close option */
    isOpen: PropTypes.bool,
    /** Extra classes */
    extraClasses: PropTypes.string,
}

FilterSection.defaultProps = {
    label: '',
    isOpen: false,
    extraClasses: '',
}

export default FilterSection
