import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Select } from '@sm360/ui-library'
import { useTranslation } from '@sm360/hooks'

import { ListingContext } from '../../configs/listing-view/state.manager'
import { pageSortingDummies } from '../../dummies/pageSorting.dummies'

const PageSorting = ({ extraClasses }) => {
    const {
        state: { vehicleCriteria, urlParams, pagination },
        addVehicleCriteria,
        dispatch,
    } = React.useContext(ListingContext)

    const { t } = useTranslation()

    const baseClass = 'il-pageSorting'
    const classNames = clsx(baseClass, extraClasses)

    const totalNoOfItems = pagination?.numberOfItems || 0

    /**
     * Handle Vehicle per Page Sorting Selected
     * @param { String } value - Selected value
     * @returns {Promise<void>}
     */
    const handleChange = async (value) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { limit: value })

        // Update Vehicle Criteria State
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
    }

    return (
        <div className={classNames}>
            <label htmlFor='pageSorting' className={`${baseClass}__label`}>
                {t('filters.pageSorting.labelBefore')}
            </label>

            <Select
                id='pageSorting'
                name='pageSorting'
                disabled={totalNoOfItems < pageSortingDummies.limit.defaultValue}
                className={`${baseClass}__select`}
                options={pageSortingDummies.limit.values}
                value={urlParams.limit || pageSortingDummies.limit.defaultValue}
                onChange={(value) => handleChange(value)}
            />

            <label htmlFor='pageSorting' className={`${baseClass}__label`}>
                {t('filters.pageSorting.labelAfter')}
            </label>
        </div>
    )
}

PageSorting.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

PageSorting.defaultProps = {
    extraClasses: '',
}

export default PageSorting
