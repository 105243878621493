import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const headingClasses = {
    h1: 'il-heading1 heading1',
    h2: 'il-heading2 heading2',
    h3: 'il-heading3 heading3',
    h4: 'il-heading4 heading4',
    h5: 'il-heading5 heading5',
    h6: 'il-heading6 heading6',
}

const headingLevel = {
    heading1: 1,
    heading2: 2,
    heading3: 3,
    heading4: 4,
    heading5: 5,
    heading6: 6,
}

/**
 * Build role value.
 * @param { String } role - A role.
 * @param { String } variant - A variant.
 * @return { Object }
 */
const getRole = (role, variant) => {
    if (role) return { role }
    else if (variant && variant.includes('heading'))
        return {
            role: 'heading',
            'aria-level': headingLevel[variant],
        }

    return {}
}

/**
 * Build variant classes.
 * @param { String } variant - A variant.
 * @param { String } tag - A tag.
 * @return { String }
 */
const buildVariant = (variant, tag) => {
    if (variant) return `il-${variant}`
    else if (headingClasses[tag]) return headingClasses[tag]

    return ''
}

const Heading = ({ Tag, children, extraClasses, subTitle, variant, role, ...extraParams }) => {
    const baseClass = 'il-heading'
    const variantClass = buildVariant(variant, Tag)
    const classNames = clsx(baseClass, variantClass, extraClasses)

    const roleAttribut = getRole(role, variant)

    return (
        <Tag className={classNames} {...roleAttribut} {...extraParams}>
            {children}
            {subTitle && <span className={`${baseClass}__subtitle`}>{subTitle}</span>}
        </Tag>
    )
}

Heading.propTypes = {
    /** `Required` - Tag */
    Tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']).isRequired,
    /** `Required` - Content */
    children: PropTypes.any.isRequired,
    /** Classes to add to the element */
    extraClasses: PropTypes.string,
    /** Subtitle text */
    subTitle: PropTypes.string,
    /** Variant */
    variant: PropTypes.oneOf([
        'heading1',
        'heading2',
        'heading3',
        'heading4',
        'heading5',
        'heading6',
        'fineprint',
        'body',
        'subtitle',
    ]),
    /** Role */
    role: PropTypes.string,
}

Heading.defaultProps = {
    extraClasses: '',
    Tag: 'h1',
    subTitle: '',
    role: '',
}

export default Heading
