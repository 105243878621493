import { Img } from '@sm360/ui-library'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'
import { getImageUrl } from '../../../../utils/image-url-generator.utils'

const VehicleImage = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            XMSConfig: {
                pictureConfiguration: { newVehiclePlaceholder, usedVehiclePlaceholder },
            },
            vehicle: { newVehicle, multimedia },
        },
    } = React.useContext(VehicleCardContext)

    const classNames = clsx('vc-vehicleImage', extraClasses)

    const noPhoto = newVehicle ? newVehiclePlaceholder : usedVehiclePlaceholder

    return (
        <Img
            className={classNames}
            {...extraParams}
            src={multimedia?.mainPictureCompleteUrl || getImageUrl(noPhoto, 'w400h300c', 'web')}
        />
    )
}

VehicleImage.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

VehicleImage.defaultProps = {
    extraClasses: '',
}

export default VehicleImage
