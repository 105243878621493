import { useTranslation } from '@sm360/hooks'
import { Radio } from '@sm360/ui-library'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { ListingContext } from '../../configs/listing-view/state.manager'
import dataLayerTags from '../../constants/dataLayerTags'
import { FILTER_GROUP_URL } from '../../constants/filterOptions'
import ga4Trigger from '../../datalayer/analytics.service'
import { changeUrl, findSelectedHubCategory } from '../../utils/handle-url.utils'
import { getPathname } from '../../utils/helper.utils'
import Heading from '../heading/Heading'

const FilterCategories = ({ extraClasses }) => {
    const {
        state: { hubCategories, hubName, vehicleCriteria },
        getEmptyCriteria,
        dispatch,
    } = React.useContext(ListingContext)
    const {
        t,
        i18n: { language },
    } = useTranslation()
    const baseClass = 'il-filterCategories'
    const classNames = clsx(baseClass, extraClasses)

    const localizedGroup = FILTER_GROUP_URL[language]
    const currentHubName = hubName.replace(localizedGroup, '')

    const triggerAnalytics = (label) => {
        ga4Trigger(dataLayerTags.LAYER_NAVIGATION__FILTERS(getPathname(), 'category'))
        ga4Trigger(dataLayerTags.LAYER_NAVIGATION__FILTERS(getPathname(), 'condition'))
        ga4Trigger(dataLayerTags.FILTER_CONDITION(label))
    }

    const handleRadioChange = (value, label) => {
        triggerAnalytics(label)
        const newCriteria = getEmptyCriteria(vehicleCriteria)
        const newHubName = hubName.search(localizedGroup) >= 0 ? `${localizedGroup}${value}` : value
        const selectedHubCategory = findSelectedHubCategory(newHubName, hubCategories, language)

        changeUrl({}, [], language, newHubName, true)
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: newCriteria })
        dispatch({ type: 'updateHubName', hubName: newHubName, selectedHubCategory })
        dispatch({ type: 'forceUpdate', forceUpdate: true })
    }

    if (hubCategories && hubCategories.length === 0) return null

    return (
        <div className={classNames}>
            <Heading Tag='p' extraClasses={`${baseClass}__heading heading3`} variant='heading3'>
                {t('filters.categories')}
            </Heading>
            <div className={`${baseClass}__radioOptions`}>
                {hubCategories
                    .filter(({ vehicleInventoryHubCategory }) => vehicleInventoryHubCategory.showOnWebsite === true)
                    .map(({ name, urlMapping, count }) => (
                        <Radio
                            key={urlMapping}
                            id={urlMapping}
                            name='categories'
                            value={urlMapping}
                            label={`${name} (${count})`}
                            checked={currentHubName === urlMapping}
                            aria-checked={currentHubName === urlMapping}
                            className={`${baseClass}__radio ${count === 0 ? 'hide' : ''}`}
                            onChange={(value) => handleRadioChange(value, name)}
                        />
                    ))}
            </div>
        </div>
    )
}

FilterCategories.propTypes = {
    /** Extra classes */
    extraClasses: PropTypes.string,
}

FilterCategories.defaultProps = {
    extraClasses: '',
}

export default FilterCategories
