import { useTranslation } from '@sm360/hooks'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import formatNumber from '../../../../../../utils/format-number.utils'

const Incentives = ({ incentives, extraClasses }) => {
    const {
        dealerRebates,
        dealerRebatesLabel,
        governmentRebates,
        governmentRebatesLabel,
        manufacturerRebates,
        manufacturerRebatesLabel,
    } = incentives || {}

    const { t } = useTranslation()

    const baseClass = 'vc-incentives'
    const classNames = clsx(baseClass, extraClasses)

    if (!dealerRebates && !governmentRebates && !manufacturerRebates) return null

    return (
        <div className={classNames}>
            {dealerRebates > 0 && (
                <div className={`${baseClass}__item`}>
                    <p className={`${baseClass}__title`}>{dealerRebatesLabel || t('paymentOptions.incentives.dealer')}</p>
                    <p className={`${baseClass}__value`}>- {formatNumber(dealerRebates, 'priceDigitsAuto')}</p>
                </div>
            )}

            {governmentRebates > 0 && (
                <div className={`${baseClass}__item`}>
                    <p className={`${baseClass}__title`}>{governmentRebatesLabel || t('paymentOptions.incentives.government')}</p>
                    <p className={`${baseClass}__value`}>- {formatNumber(governmentRebates, 'priceDigitsAuto')}</p>
                </div>
            )}

            {manufacturerRebates > 0 && (
                <div className={`${baseClass}__item`}>
                    <p className={`${baseClass}__title`}>
                        {manufacturerRebatesLabel || t('paymentOptions.incentives.manufacturer')}
                    </p>
                    <p className={`${baseClass}__value`}>- {formatNumber(manufacturerRebates, 'priceDigitsAuto')}</p>
                </div>
            )}
        </div>
    )
}

Incentives.propTypes = {
    /** Incentives */
    incentives: PropTypes.object,
    /** Extra classes */
    extraClasses: PropTypes.string,
}

Incentives.defaultProps = {
    extraClasses: '',
    incentives: {},
}

export default Incentives
