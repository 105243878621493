import axios from 'axios'

import { axiosErrorHandler, buildInventoryUrl, buildUrlWithParameters } from '../../utils/handle-url.utils'

const config = {
    headers: { authorization: `Basic c20zNjA6c20zNjA=` },
}

/**
 * Get Inventory Listing
 * @param { String } hub - Name of hubCategory
 * @param { String } lang - Language
 * @param { Object } params - The params object
 * @returns {Promise<{}>}
 */
export const getInventoryListingApiUrl = async (hub, lang = 'en', params = {}, domain, paymentOptions = {}) => {
    if (!hub) return null

    let apiResponse = {}

    const apiUrl = `/${lang}/${buildInventoryUrl(lang, hub)}/api/listing`

    const defaultParams = {
        imageSize: 'w400h300c',
        limit: 12,
        paymentOptionPurchaseMethod: 'cash',
    }

    const url = domain || window?.web_domain
    const urlComplete = `${url.concat(apiUrl)}`

    const urlWithParams = buildUrlWithParameters(urlComplete, {
        ...defaultParams,
        ...params,
        ...paymentOptions,
    })
    const response = await axios.get(urlWithParams, config).catch(axiosErrorHandler)
    if (response) {
        apiResponse = response.data
    }
    return apiResponse
}
