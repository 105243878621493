import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { changeUrl } from '../../utils/handle-url.utils'
import { ListingContext } from '../../configs/listing-view/state.manager'
import { useTranslation } from '@sm360/hooks'
import { scrollToElementTop } from '../../utils/global-functions.utils'

const PaginationItem = ({ currentPage, isDisabled, extraClasses, children }) => {
    const {
        state: { filterMetaData, vehicleCriteria, hubName },
        dispatch,
        addVehicleCriteria,
    } = React.useContext(ListingContext)

    const {
        i18n: { language },
    } = useTranslation()

    const baseClass = 'il-paginationItem'
    const classNames = clsx(baseClass, isDisabled ? '-disabled' : '', extraClasses)

    const onPageChange = async (pageNumber) => {
        const criteria = addVehicleCriteria(vehicleCriteria, { page: pageNumber })

        changeUrl(criteria, filterMetaData, language, hubName)
        // Update Vehicle Criteria State
        dispatch({ type: 'updateVehicleCriteria', vehicleCriteria: criteria })
        // Scroll To Page Top
        scrollToElementTop()
    }

    return (
        <button className={classNames} onClick={() => onPageChange(currentPage)} disabled={isDisabled}>
            {children}
        </button>
    )
}

PaginationItem.propTypes = {
    /** Current Page */
    currentPage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Pagination Item extra Classes */
    extraClasses: PropTypes.string,
    /** Disabled Item */
    isDisabled: PropTypes.bool,
}

PaginationItem.defaultProps = {
    currentPage: 0,
    extraClasses: '',
    isDisabled: false,
}

export default PaginationItem
