import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const VehiclesSkeleton = ({ extraClasses }) => {
    const baseClass = 'vc-alpha'

    const mainClass = 'il-vehicles'
    const classNames = clsx(mainClass, extraClasses)

    return (
        <section className={classNames}>
            {[...Array(12)].map((e, i) => (
                <article className={baseClass} key={i}>
                    <header className={`${baseClass}__header`}>
                        <div className='skeleton skeleton-image'></div>
                    </header>

                    <main className={`${baseClass}__section`}>
                        <div className='skeleton skeleton-text'></div>
                        <div className='skeleton skeleton-text'></div>
                        <div className='skeleton skeleton-text'></div>
                        <div className='skeleton skeleton-text'></div>
                    </main>

                    <footer className={`${baseClass}__footer`}>
                        <div className='skeleton skeleton-button'></div>
                    </footer>
                </article>
            ))}
        </section>
    )
}

VehiclesSkeleton.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

VehiclesSkeleton.defaultProps = {
    extraClasses: '',
}

export default VehiclesSkeleton
