import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@sm360/ui-library'
import clsx from 'clsx'

const SpecsItem = ({ label, icon, extraClasses }) => {
    const baseClass = 'vc-specsItem'
    const classNames = clsx(baseClass, extraClasses)

    return (
        <div className={classNames}>
            <Icon name={icon} className={`${baseClass}__icon`} />
            <span className={`${baseClass}__label`}>{label}</span>
        </div>
    )
}

SpecsItem.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
    /** Icon */
    icon: PropTypes.string,
    /** Label */
    label: PropTypes.string,
}

SpecsItem.defaultProps = {
    extraClasses: '',
    icon: '',
    label: '',
}

export default SpecsItem
