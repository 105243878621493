import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Badge } from '@sm360/ui-library'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'

const highlightIsActive = (highlightListActive, highlight) => {
    return highlightListActive.some(({ highlightDefinition }) => highlightDefinition.code === highlight)
}

const highlightInfo = (highlight, index, classNames) => {
    const { label, backgroundColor, fontColor, priority } = highlight

    let labelToCamelCase = label.replace(/[_ -]\w/g, (match) => match[1].toUpperCase())
    labelToCamelCase = labelToCamelCase.replace(/^\w/, (match) => match.toLowerCase())

    return (
        <Badge
            key={index}
            className={`${classNames}__item -${labelToCamelCase}`}
            style={{
                backgroundColor,
                color: fontColor,
                order: priority,
            }}
            label={label}
            size='small'
        />
    )
}

const VehicleHighlights = ({ extraClasses, ...extraParams }) => {
    const {
        state: {
            XMSConfig: { highlightConfigurations },
            vehicle: {
                demo,
                featured,
                clearance,
                extendedWarranty,
                inspected,
                asTraded,
                comingSoon,
                wholesale,
                commercialVehicle,
                custom,
                onConsignment,
            },
        },
    } = React.useContext(VehicleCardContext)

    const hasDemo = demo && highlightIsActive(highlightConfigurations, 'demo')
    const hasFeatured = featured && highlightIsActive(highlightConfigurations, 'featured')
    const hasClearance = clearance && highlightIsActive(highlightConfigurations, 'clearance')
    const hasExtendedWarranty = extendedWarranty && highlightIsActive(highlightConfigurations, 'extended_warranty')
    const hasInspected = inspected && highlightIsActive(highlightConfigurations, 'inspected')
    const hasAsTraded = asTraded && highlightIsActive(highlightConfigurations, 'as_traded')
    const hasComingSoon = comingSoon && highlightIsActive(highlightConfigurations, 'coming_soon')
    const hasWholesale = wholesale && highlightIsActive(highlightConfigurations, 'wholesale')
    const hasCommercialVehicle = commercialVehicle && highlightIsActive(highlightConfigurations, 'commercial_vehicle')
    const hasCustom = custom && highlightIsActive(highlightConfigurations, 'custom')
    const hasOnConsignment = onConsignment && highlightIsActive(highlightConfigurations, 'on_consignment')

    const baseClass = 'vc-vehicleHighlights'
    const classNames = clsx(baseClass, extraClasses)

    if (
        !hasDemo &&
        !hasCommercialVehicle &&
        !hasCustom &&
        !hasFeatured &&
        !hasClearance &&
        !hasExtendedWarranty &&
        !hasInspected &&
        !hasAsTraded &&
        !hasComingSoon &&
        !hasWholesale &&
        !hasOnConsignment
    )
        return <div className={classNames}></div>

    return (
        <div className={classNames} {...extraParams}>
            {highlightConfigurations.map((highlight, index) => {
                const { code } = highlight.highlightDefinition

                if (
                    (hasDemo && code === 'demo') ||
                    (hasFeatured && code === 'featured') ||
                    (hasClearance && code === 'clearance') ||
                    (hasExtendedWarranty && code === 'extended_warranty') ||
                    (hasInspected && code === 'inspected') ||
                    (hasAsTraded && code === 'as_traded') ||
                    (hasWholesale && code === 'wholesale') ||
                    (hasCommercialVehicle && code === 'commercial_vehicle') ||
                    (hasCustom && code === 'custom') ||
                    (hasOnConsignment && code === 'on_consignment') ||
                    (hasComingSoon && code === 'coming_soon')
                )
                    return highlightInfo(highlight, index, baseClass)
                else return null
            })}
        </div>
    )
}

VehicleHighlights.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

VehicleHighlights.defaultProps = {
    extraClasses: '',
}

export default VehicleHighlights
