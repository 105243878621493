const getStructuredData = (
    {
        stockNo,
        make,
        model,
        bodyStyle,
        exteriorColor,
        interiorColor,
        category,
        fuel,
        engine,
        odometer,
        transmissionDetails,
        driveTrain,
        extraOptions,
        vehicleStatus,
        daysInInventory,
        salePrice,
        // primaryOrganizationUnit,
        vehicleVdpUrl,
        vehicleName,
        vehicleImageUrl,
    },
    lang
) => {
    return {
        '@context': 'https://schema.org',
        '@type': 'Vehicle',
        vehicleIdentificationNumber: '',
        sku: stockNo,
        brand: make?.name, // Make
        name: vehicleName, // Year Make Model
        manufacturer: {
            '@type': 'Organization',
            name: make?.name,
        },
        bodyType: {
            '@type': 'Thing',
            name: bodyStyle?.name,
        },
        description: '',
        image: vehicleImageUrl, // Main Picture
        color: {
            '@type': 'Thing',
            name: lang === 'fr' ? exteriorColor?.colorFr : exteriorColor?.colorEn, // exterior color
        },
        vehicleInteriorColor: {
            '@type': 'Thing',
            name: lang === 'fr' ? interiorColor?.colorFr : interiorColor?.colorEn,
        },
        category, // car
        fuelType: {
            '@type': 'Thing',
            name: fuel?.name,
        },
        vehicleEngine: {
            '@type': 'EngineSpecification',
            description: engine?.description,
            engineType: engine?.type,
        },
        mileageFromOdometer: {
            '@type': 'QuantitativeValue',
            value: odometer,
        },
        vehicleTransmission: {
            '@type': 'Thing',
            name: transmissionDetails?.name,
        },
        driveWheelConfiguration: {
            '@type': 'DriveWheelConfigurationValue',
            name: driveTrain, // ex: All Wheel Drive
        },
        additionalProperty: [
            {
                '@type': 'PropertyValue',
                name: 'Extra Options',
                value: extraOptions, // ex: Driver Air Bag,Passenger Air Bag,Front Side Air Bag,Front Head Air Bag
            },
            {
                '@type': 'PropertyValue',
                name: 'Vehicle Status',
                value: vehicleStatus,
            },
            {
                '@type': 'PropertyValue',
                name: 'Days in Inventory',
                value: daysInInventory,
            },
        ],
        offers: {
            '@type': 'Offer',
            priceCurrency: 'CAD',
            price: salePrice, // salePrice
            availability: 'https://schema.org/InStock',
            url: vehicleVdpUrl, // url to vdp
        },
        // seller: {
        //     '@type': 'Organization',
        //     name: primaryOrganizationUnit?.name, // ex: Bayfield Ford
        //     address: {
        //         '@type': 'PostalAddress',
        //         addressLocality: primaryOrganizationUnit?.city, // ex: Barrie
        //         addressRegion: primaryOrganizationUnit?.province?.provinceCode, // ex: ON
        //         addressCountry: primaryOrganizationUnit?.province?.province, // ex: Ontario
        //     },
        // },
    }
}

export default getStructuredData
