import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const TaxesDisclaimer = ({ taxesDisclaimer, extraClasses }) => {
    const baseClass = 'vc-taxesDisclaimer'
    const classNames = clsx(baseClass, extraClasses)

    if (!taxesDisclaimer) return null

    return <p className={classNames}>{taxesDisclaimer}</p>
}

TaxesDisclaimer.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
    /** Disclaimer Infos */
    taxesDisclaimer: PropTypes.string,
}

TaxesDisclaimer.defaultProps = {
    extraClasses: '',
    taxesDisclaimer: '',
}

export default TaxesDisclaimer
