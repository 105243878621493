import { Icon } from '@sm360/ui-library'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

import { VehicleCardContext } from '../../../../configs/vehicle-card-view/state.manager'
import { isExternalUrl } from '../../../../utils/handle-url.utils'

const LocationAlert = ({ extraClasses }) => {
    const {
        state: {
            vehicle: { locationBanner },
        },
    } = React.useContext(VehicleCardContext)

    const baseClass = 'vc-locationAlert'
    const classNames = clsx(baseClass, extraClasses)

    if (locationBanner) {
        const { url, label, backgroundColor, fontColor, picto } = locationBanner
        const CustomTag = url ? 'a' : 'div'
        const styles = { backgroundColor, color: fontColor }
        return (
            <CustomTag href={url} target={isExternalUrl(url) ? '_blank' : undefined} className={classNames} style={styles}>
                {picto && <Icon name={picto} className={`${baseClass}__icon`} />}
                <p className={`${baseClass}__text`}>{label}</p>
            </CustomTag>
        )
    }

    return null
}

LocationAlert.propTypes = {
    /** Extra Classes */
    extraClasses: PropTypes.string,
}

LocationAlert.defaultProps = {
    extraClasses: '',
}

export default LocationAlert
