const MOST_POPULAR_VEHICLES = (pagePath, model) => ({
    category: 'behavior_showroom_most_popular_vehicles',
    action: 'click',
    label: `${pagePath} | ${model}`,
})

const LAYER_NAVIGATION__FILTERS = (pagePath, filter) => ({
    category: 'ux_showroom_layer_navigation_filter',
    label: `${pagePath} | ${filter}`,
})

const FILTER_CONDITION = (condition) => ({
    category: 'ux_showroom_condition_filter',
    condition,
})

const FILTER_SORT_BY = (sortBy) => ({
    category: 'ux_showroom_inventory_listing_sort_by',
    sortBy,
})

const FILTER_SEARCH = (query) => ({
    category: 'ux_showroom_searchbar_query',
    query,
})

const FILTER_OPTIONS = (filter, option) => {
    const options = {
        interiorColorId: {
            category: 'ux_showroom_interior_colours_filter',
            filterInteriorColour: option,
        },
        exteriorColorId: {
            category: 'ux_showroom_colours_filter',
            filterColour: option,
        },
        modelId: {
            category: 'ux_showroom_model_filter',
            filterModel: option,
        },
        transmissionId: {
            category: 'ux_showroom_transmission_filter',
            filterTransmission: option,
        },
        fuelId: {
            category: 'ux_showroom_fuel_type_filter',
            filterFuelType: option,
        },
        driveTrainId: {
            category: 'ux_showroom_drivetrain_filter',
            filterDrivetrain: option,
        },
        passengers: {
            category: 'ux_showroom_passengers_filter',
            filterPassengers: option,
        },
        doors: {
            category: 'ux_showroom_doors_filter',
            filterDoors: option,
        },
        yearRange: {
            category: 'ux_showroom_year_filter',
            year: option,
        },
        odometerRange: {
            category: 'ux_showroom_odometer_filter',
            filterOdometer: option,
        },
        frameStyleId: {
            category: 'ux_showroom_body_style',
            getEventLabel: option,
        },
    }

    return options[filter]
}

const dataLayerTags = {
    MOST_POPULAR_VEHICLES,
    LAYER_NAVIGATION__FILTERS,
    FILTER_CONDITION,
    FILTER_OPTIONS,
    FILTER_SORT_BY,
    FILTER_SEARCH,
}

/**
 * Used to convert the keys from backend to the analytics variables
 */
export const FILTER_MAP = (type) => {
    const filters = {
        driveTrainId: 'driveTrains',
        transmissionId: 'transmissionTypes',
        modelId: 'modelIds',
        door: 'doors',
        interiorColorId: 'interiorColor',
        exteriorColorId: 'exteriorColor',
        fuelId: 'fuelTypes',
        frameStyleId: 'frameStyleTypes',
        priceRange: 'salePriceRange',
    }
    return filters[type] || type
}

export default dataLayerTags
