import { FILTER_GROUP_URL, INVENTORY_TYPE } from '../constants/filterOptions'
import { INVENTORY_TITLE_TYPES } from '../constants/inventoryTitleType'
import { runtimeConfig } from '../widget-ssr/config/runtimeConfig'
import { isArray } from './helper.utils'
import { buildUrlWithParameters } from './handle-url.utils'

/**
 * Generate Vehicle Form Url with Parameters
 * @param { String } make - Vehicle Make Name
 * @param { String } model - Vehicle Model Name
 * @param { Number | String } year - Vehicle Year
 * @param { String } stockNo - Vehicle Stock No.
 * @param { Number | String } formUrl - Form Url
 * @returns {String} - Form Url with Vehicle Params
 */
export const generateVehicleFormUrl = (make, model, year, stockNo, formUrl, isBrowser, domain) => {
    const params = {
        desired_make: make,
        desired_model: model,
        desired_year: year,
        stocknumber: stockNo,
    }

    const parameters = []

    const location = domain || runtimeConfig.REACT_APP_DOMAIN

    const url = isBrowser ? window.location.origin.concat(formUrl) : location.concat(formUrl)

    Object.entries(params).map(([key, value]) => value && parameters.push(`${key}=${value}`))

    return buildUrlWithParameters(url, parameters.join('&'))
}

/**
 * Check inventory type based on vehicles and current hub
 * @param { Array } vehicles - Vehicle list
 * @param { String } hubName - Hub name
 * @returns {String} - inventory type as string
 */
export const getInventoryType = (vehicles, hubName) => {
    const newVehicles = vehicles && vehicles.length > 0 && vehicles.some((vehicle) => vehicle.newVehicle)
    const usedVehicles = vehicles && vehicles.length > 0 && vehicles.some((vehicle) => !vehicle.newVehicle)

    if (hubName === INVENTORY_TYPE.ALL || (newVehicles && usedVehicles)) return INVENTORY_TYPE.ALL
    if (newVehicles) return INVENTORY_TYPE.NEW

    return INVENTORY_TYPE.USED
}

/**
 * Get hub category from hub name
 * @param { Array } hubCategories - list of hub categories
 * @param { String } hubName - Hub name
 * @param { String } language - Language
 * @returns Hub category object
 */

export const getHubCategory = (hubCategories, hubName, language) => {
    let urlMapping = hubName
    const isGroupHub = hubName.search(`${FILTER_GROUP_URL[language]}`) >= 0
    if (isGroupHub) {
        urlMapping = hubName.replace(`${FILTER_GROUP_URL[language]}`, '')
    }
    if (hubCategories && isArray(hubCategories) && hubCategories.length > 0) {
        const hubCategory = hubCategories?.find((category) => category.urlMapping === urlMapping)
        return hubCategory || undefined
    }
    return undefined
}

/**
 * Return h1 / seo title
 * @param {String} titleType - h1 or seo
 * @param {String} slug - hub category slug
 * @param {Object} vehicleCriteria - Selected filters
 * @param {Array} filters - Filters
 * @param {String} orgUnitName - Dealer Unit/group name
 * @param {String} orgUnitCity - Organization location city
 * @param {String} language - Selcted language
 * @param {Object} seoContent - Seo Meta data
 * @param {Array} filterMetaData - Filter data
 * @param {String} hubCategoryName - Hub name by category
 * @param {Boolean} isGroupHub - Boolean if the hub type is group or not
 * @returns {String} - Title to display
 */
export const getInventoryTitle = (
    titleType,
    slug,
    vehicleCriteria,
    filters,
    orgUnitName,
    orgUnitCity,
    language,
    seoContent,
    filterMetaData,
    hubCategoryName,
    isGroupHub = false
) => {
    let selection = ''

    // To check if the range value is the same or different
    const isSameRange = (vehicleCriteria, filters, rangeType) => {
        const isSame =
            vehicleCriteria[`min${rangeType}`] &&
            vehicleCriteria[`max${rangeType}`] &&
            vehicleCriteria[`min${rangeType}`] === vehicleCriteria[`max${rangeType}`]
        const isMin = vehicleCriteria[`min${rangeType}`] && parseInt(vehicleCriteria[`min${rangeType}`]) === filters?.max
        const isMax = vehicleCriteria[`max${rangeType}`] && parseInt(vehicleCriteria[`max${rangeType}`]) === filters?.min

        return isSame || isMin || isMax
    }

    if (vehicleCriteria.makeId?.length === 1) {
        selection += '.make'
    }

    if (vehicleCriteria.modelId?.length === 1) {
        selection += '.model'
    }

    // To validate if selection is yearRange or yearRangeAll
    const isYerRange = filterMetaData.find((metadata) => metadata.name === 'yearRange')
    const sameYearRangeValue = isSameRange(vehicleCriteria, isYerRange ? filters?.yearRange : filters?.yearRangeAll, 'Year')
    if (sameYearRangeValue) {
        selection += '.year'
    }

    let value = ''
    if (
        slug &&
        (seoContent[`page.${isGroupHub ? 'group.' : ''}inventory.${slug}.${titleType}`] !== undefined ||
            seoContent[`page.inventory.${slug}.${titleType}`] !== undefined)
    ) {
        value =
            seoContent[`page.${isGroupHub ? 'group.' : ''}inventory.${slug}.${titleType}`]?.[language] ||
            seoContent[`page.inventory.${slug}.${titleType}`]?.[language]
    }

    if (seoContent[`page.inventory${selection}.${titleType}`] !== undefined) {
        value = seoContent[`page.inventory${selection}.${titleType}`]?.[language]
    }

    selection = `${slug}${selection}`

    if (
        seoContent[`page.${isGroupHub ? 'group.' : ''}inventory.${selection}.${titleType}`] !== undefined ||
        seoContent[`page.inventory.${selection}.${titleType}`] !== undefined
    ) {
        value =
            seoContent[`page.${isGroupHub ? 'group.' : ''}inventory.${selection}.${titleType}`]?.[language] ||
            seoContent[`page.inventory.${selection}.${titleType}`]?.[language]
    }

    if (!value) {
        // Check for any default key
        value =
            seoContent[`page.inventory.default.${titleType}`]?.[language] ||
            seoContent[`page.inventory.fallback.${titleType}`]?.[language] ||
            ''
    }

    const replaceValue = (key, value, replacement, searchUrl) => {
        let finalValue = value
        // TODO: Find a better way to fix the URL issue
        // Replace text as lowercase if it's an url
        if (finalValue?.includes(`/${key}`) && searchUrl) {
            finalValue = finalValue.replaceAll(`/${key}`, `/${replacement.toLowerCase()}`)
        }
        if (finalValue?.includes(key)) {
            // The invisible character is added otherwise the text is not replaced
            finalValue = finalValue.replaceAll(key, replacement)
        }
        return finalValue
    }

    value = replaceValue('{inventoryHubType}', value, hubCategoryName || '')

    if (value.includes('{make}')) {
        if (vehicleCriteria.makeId?.length > 0 && vehicleCriteria.makeId.length === 1) {
            const make = filters?.makeId?.find((make) => make.key === vehicleCriteria?.makeId[0])
            value = replaceValue('{make}', value, make?.label, true)
        } else {
            value = replaceValue('{make}', value, '')
        }
    }

    if (value.includes('{makes}')) {
        if (!value.includes('{make}') && vehicleCriteria.makeId?.length > 0) {
            const makes = filters?.makeId?.filter((make) => vehicleCriteria?.makeId.includes(make.key))
            const makesLabel = makes?.map((make) => make.label)
            value = replaceValue('{makes}', value, makesLabel?.toString())
        } else {
            value = replaceValue('{makes}', value, '')
        }
    }

    // Validation for {model}
    if (vehicleCriteria.modelId?.length === 1) {
        const model = filters?.modelId?.find((model) => model.key === vehicleCriteria?.modelId[0])
        value = replaceValue('{model}', value, model?.label, true)
    } else {
        value = replaceValue('{model}', value, '')
    }

    // Validation for {year}
    if (sameYearRangeValue) {
        value = replaceValue('{year}', value, vehicleCriteria?.minYear || vehicleCriteria?.maxYear)
    } else {
        value = replaceValue('{year}', value, '')
    }

    // Validation for {trim}
    if (vehicleCriteria.trimId?.length === 1) {
        const trim = filters?.trimId.find((trim) => trim.key === vehicleCriteria?.trimId[0])
        value = replaceValue('{trim}', value, trim?.label)
    } else {
        value = replaceValue('{trim}', value, '')
    }

    // Validation for {price}
    const isPriceRange = filterMetaData.find((metadata) => metadata.name === 'priceRange')
    const samePriceRangeValue = isSameRange(vehicleCriteria, isPriceRange ? filters?.priceRange : filters?.priceRangeAll, 'Price')
    if (samePriceRangeValue) {
        value = replaceValue('{price}', value, vehicleCriteria?.minPrice || vehicleCriteria?.maxPrice)
    } else {
        value = replaceValue('{price}', value, '')
    }

    // Validation for {odometer}
    const isOdometerRange = filterMetaData.find((metadata) => metadata.name === 'odometerRange')
    const sameOdometerRangeValue = isSameRange(
        vehicleCriteria,
        isOdometerRange ? filters?.odometerRange : filters?.odometerRangeAll,
        'Odometer'
    )
    if (sameOdometerRangeValue) {
        value = replaceValue('{odometer}', value, vehicleCriteria?.minOdometer || vehicleCriteria?.maxOdometer)
    } else {
        value = replaceValue('{odometer}', value, '')
    }

    // Need to display 'at {name} in {city}' text as subtitle of the main for H1 only
    if (titleType === INVENTORY_TITLE_TYPES.H1 && (value.includes('at {name}') || value.includes('à {name}'))) {
        const subTitle1 = value.includes('at {name}') ? 'at {name}' : 'à {name}'
        const subTitle2 = value.split(subTitle1)?.[1]
        let subTitle = `${subTitle1}${subTitle2}`

        const mainTitle = value.replace(subTitle, '')

        subTitle = replaceValue('{name}', subTitle, orgUnitName)
        subTitle = replaceValue('{city}', subTitle, orgUnitCity)
        return {
            mainTitle,
            subTitle,
        }
    }
    // Validation for {name}
    value = replaceValue('{name}', value, orgUnitName)
    // Validation for {city}
    value = replaceValue('{city}', value, orgUnitCity)

    return { content: value }
}

/**
 * Reset Parents Overflow
 * @param { String } attributName - Attribut name to check parents
 */
export const resetParentsOverflow = (attributName) => {
    let parent = document.querySelector(`[${attributName}]`)?.parentElement

    if (parent) {
        while (parent) {
            const hasOverflow = getComputedStyle(parent).overflow
            if (hasOverflow !== 'visible') {
                parent.style.overflow = 'initial'
            }
            parent = parent.parentElement
        }
    }
}

/**
 * Scroll To Element Top
 * @param { string } elementId - HTML Element
 */
export const scrollToElementTop = (elementId = '') => {
    window.scrollTo({
        top: elementId ? document.getElementById(elementId).offsetTop : 0,
        behavior: 'smooth',
    })
}

export const setMetaData = (
    metaType,
    slug,
    vehicleCriteria,
    filters,
    orgUnitName,
    orgUnitCity,
    language,
    combinedContent,
    filterMetaData,
    hubCategoryName,
    isGroupHub
) => {
    const { mainTitle, content, subTitle } = getInventoryTitle(
        metaType,
        slug,
        vehicleCriteria,
        filters,
        orgUnitName,
        orgUnitCity,
        language,
        combinedContent,
        filterMetaData,
        hubCategoryName,
        isGroupHub
    )

    if ((mainTitle || content) && metaType === INVENTORY_TITLE_TYPES.H1) {
        return {
            mainTitle,
            content,
            subTitle,
        }
    }
    return ''
}

/**
 * Creates a min height for all the children elements
 * of an HTML node specified by the class names
 * @param { array } elements - list of parent HTML Elements
 * @param { array } classNames - list of class names to lookup from the parent
 */
export const alignCardItems = (elements, classNames) => {
    const getHeights = (items) => {
        const heights = []
        items.forEach((item) => {
            classNames.forEach((className, index) => {
                const subitem = item.querySelector(className)
                if (subitem)
                    heights[index] = subitem.offsetHeight > (heights[index] || 0) ? subitem.offsetHeight : heights[index] || 0
            })
        })
        return heights
    }

    const setElementHeights = (items, heights) => {
        items.forEach((item) => {
            classNames.forEach((className, index) => {
                const subitem = item.querySelector(className)
                if (!subitem) return false

                subitem.style.minHeight = `${heights[index]}px`
            })
        })
    }

    const heights = getHeights(elements)

    setElementHeights(elements, heights)
}
