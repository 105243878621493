import { getStorageValue, useTranslation } from '@sm360/hooks'
import PropTypes from 'prop-types'
import React from 'react'

import { getHubsListAPIUrl, getInventoryFiltersAPIUrl } from '../../apis/filters/filters.service'
import { getInventoryPromotionsApiUrl } from '../../apis/promotion/promotion.service'
import { getShowroom360ConfigListingApiUrl } from '../../apis/showroom360/showroom360.service'
import { getInventoryListingApiUrl } from '../../apis/vehicles/vehicles.service'
import { FILTER_OPTIONS_REMOVED_FROM_URL, FILTER_OPTIONS_TYPES } from '../../constants/filterOptions'
import { FilterMetaDataModel } from '../../models/FilterMetaDataModel'
import { motoInsight } from '../../third-parties/thirdParties'
import {
    extractPathUrlInfoAsObject,
    findSelectedHubCategory,
    getHubPathName,
    handleCanonicalUrl,
} from '../../utils/handle-url.utils'
import { isEmptyObject, isNumber, isObject, sortBy } from '../../utils/helper.utils'

const initialState = {
    forceUpdate: false,
    isFetching: true,
    paymentInformationUpdate: false,
    vehicles: [],
    pagination: {},
    paymentOptions: {},
    filters: {},
    promoBanner: [],
    promoCards: [],
    pathUrlInfos: {
        lang: '',
        inventory: '',
        makeName: '',
        modelName: '',
        langUrl: '',
        inventoryUrl: '',
        makeUrl: '',
        modelUrl: '',
    },
    XMSConfig: {
        configuration: {},
        conditionsConfigurations: [],
        highlightConfigurations: [],
        inventoryNewCompareConfiguration: {},
        inventoryUsedCompareConfiguration: {},
        pictureConfiguration: {},
        statusConfigurations: [],
        taggingConfigurations: [],
        filtersConfigurations: {},
    },
    dealerInfos: {
        dealerBac: '',
        domain: '',
        lang: '',
        location: '',
        orgId: '',
        orgUnitCity: '',
        orgUnitId: '',
        orgUnitMakes: [],
        orgUnitName: '',
        orgUnitProvince: '',
        websiteId: '',
    },
    hubName: '',
    hubCategories: [],
    hubGroupCategories: [],
    sortConfigurations: [],
    urlParams: {},
    vehicleCriteria: {},
    vehicleCtas: [],
    filterMetaData: [],
    seoContent: [],
    resetHubCategories: false,
    isFetchingFilters: false,
    isInitialFetched: false,
    selectedHubCategory: {},
    icomoon: {},
    packageVersion: '',
    tradeInWidget: {
        tradeInBeforePayments: '',
        tradeInAfterPayments: '',
        tradeInBeforeListing: '',
        tradeInAfterListing: '',
    },
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'fetching': {
            return {
                ...state,
                isFetching: true,
            }
        }
        case 'fetchSucceed': {
            return {
                ...state,
                isFetching: false,
            }
        }
        case 'updateState': {
            return {
                ...state,
                ...action.state,
            }
        }
        case 'init': {
            return {
                ...state,
                vehicles: action.vehicles,
                pagination: action.pagination,
                paymentOptions: action.paymentOptions,
                promoBanner: action.promoBanner,
                promoCards: action.promoCards,
                filters: action.filters,
                hubCategories: action.hubCategories,
                hubGroupCategories: action.hubGroupCategories,
                hubName: action.hubName,
                urlParams: action.urlParams,
                pathUrlInfos: action.pathUrlInfos,
                dealerInfos: action.dealerInfos,
                XMSConfig: action.XMSConfig,
                vehicleCriteria: action.vehicleCriteria,
                vehicleCtas: action.vehicleCtas,
                filterMetaData: action.filterMetaData,
                sortConfigurations: action.sortConfigurations,
                seoContent: action.seoContent,
                isInitialFetched: true,
                selectedHubCategory: action.selectedHubCategory,
                icomoon: action.icomoon,
                packageVersion: action.packageVersion,
                tradeInWidget: action.tradeInWidget,
                isFetching: action.isFetching,
            }
        }
        case 'updateVehicles': {
            return {
                ...state,
                vehicles: action.vehicles,
                pagination: action.pagination,
                paymentOptions: action.paymentOptions,
                // filterMetaData: action.filterMetaData,
            }
        }
        case 'updateVehicleCriteria': {
            return {
                ...state,
                vehicleCriteria: action.vehicleCriteria,
            }
        }
        case 'forceUpdate': {
            return {
                ...state,
                forceUpdate: action.forceUpdate,
            }
        }
        case 'updateFilters': {
            return {
                ...state,
                filters: action.filters,
                filterMetaData: action.filterMetaData,
                promoBanner: action.promoBanner,
                promoCards: action.promoCards,
            }
        }
        case 'updateHubName': {
            return {
                ...state,
                hubName: action.hubName,
                selectedHubCategory: action.selectedHubCategory,
            }
        }
        case 'updateHubCategories': {
            return {
                ...state,
                hubCategories: action.hubCategories,
                hubGroupCategories: action.hubGroupCategories,
            }
        }
        case 'updatePaymentInformation': {
            return {
                ...state,
                paymentInformationUpdate: action.paymentInformationUpdate,
            }
        }
        case 'resetHubCategories': {
            return {
                ...state,
                resetHubCategories: action.resetHubCategories,
            }
        }
        case 'fetchingFilters': {
            return {
                ...state,
                isFetchingFilters: action.isFetchingFilters,
            }
        }
        case 'updatePromoCards': {
            return {
                ...state,
                promoCards: action.promoCards,
            }
        }
        case 'updatePromoCardsAndBanner': {
            return {
                ...state,
                promoCards: action.promoCards,
                promoBanner: action.promoBanner,
            }
        }
        default:
            return state
    }
}

const ListingContext = React.createContext(initialState)

const ListingProvider = ({ initialData, children }) => {
    const [state, dispatch] = React.useReducer(reducer, initialState)

    const {
        isFetching,
        vehicleCriteria,
        forceUpdate,
        resetHubCategories,
        isInitialFetched,
        selectedHubCategory,
        hubName: initialHubName,
        paymentInformationUpdate,
        dealerInfos: { orgUnitId, location, lang: initialLang },
    } = state

    const { t, i18n } = useTranslation()

    const lang = initialLang || i18n?.language
    const hubName = initialHubName || getHubPathName(lang, location, orgUnitId)

    const paramsPromoCards = {
        location: 'inventory.vehicles.promo.card',
    }

    if (!isInitialFetched) {
        if (initialData) {
            dispatch({ type: 'init', ...initialData })
        }
    }

    React.useEffect(() => {
        if (!isFetching && window.MOTOINSIGHT) motoInsight()
    }, [isFetching])

    React.useEffect(() => {
        fetchPromoCardAndBanner()
        fetchListingVehicles()
    }, [])

    React.useEffect(() => {
        const fetchData = async () => {
            const urlParams = await fetchUrlParams()
            handleCanonicalUrl(lang)
            if (Object.keys(vehicleCriteria).length > 0 || forceUpdate) {
                fetchFilteredInventoryListing(hubName, urlParams)
            }
            if (forceUpdate) {
                fetchFilterData()
                dispatch({ type: 'forceUpdate', forceUpdate: false })
            }
        }
        fetchData()
    }, [vehicleCriteria, forceUpdate])

    React.useEffect(() => {
        if (resetHubCategories) {
            fetchHubCategories()
            dispatch({ type: 'resetHubCategories', resetHubCategories: false })
        }
    }, [resetHubCategories])

    React.useEffect(() => {
        const fetchVehicles = async () => {
            if (paymentInformationUpdate) {
                fetchListingVehicles()
                dispatch({ type: 'updatePaymentInformation', paymentInformationUpdate: false })
            }
        }
        fetchVehicles()
    }, [paymentInformationUpdate])

    const fetchFilterData = async () => {
        dispatch({ type: 'fetchingFilters', isFetchingFilters: true })
        try {
            const urlParams = await fetchUrlParams()
            // TODO: Remove Remove this call after getting combined filter data from fitler API
            const { filtersConfigurations } = await getShowroom360ConfigListingApiUrl(lang)
            // Get Inventory Listing - Filters
            const { data: filters } = await getInventoryFiltersAPIUrl(hubName, lang, urlParams)
            // TODO: We are serializing the whole filter data now,
            // Once we get only the Configurable filter data this might need to change
            const filterMetaData = getFilterMetaData(filters, filtersConfigurations, urlParams).sort(sortBy('order'))
            // Get Promotions Banner
            const slug = selectedHubCategory?.vehicleInventoryHubCategory?.slug || ''
            const paramsPromoBanner = {
                location: `page.inventory-hub.${slug.toLowerCase()}.header`,
            }
            const promoBanner = await getInventoryPromotionsApiUrl(lang, paramsPromoBanner)

            const promoCards = await getInventoryPromotionsApiUrl(lang, paramsPromoCards)

            dispatch({
                type: 'updateFilters',
                filterMetaData,
                filters,
                promoBanner: promoBanner?.promotions || [],
                promoCards: promoCards?.promotions || [],
                selectedHubCategory,
            })
            dispatch({ type: 'fetchingFilters', isFetchingFilters: false })
        } catch (error) {
            console.error(error)
        }
    }

    const fetchPromoCardAndBanner = async () => {
        try {
            const slug = selectedHubCategory?.vehicleInventoryHubCategory?.slug || ''
            const paramsPromoBanner = {
                location: `page.inventory-hub.${slug.toLowerCase()}.header`,
            }
            const promoBanner = await getInventoryPromotionsApiUrl(lang, paramsPromoBanner)

            const promoCards = await getInventoryPromotionsApiUrl(lang, paramsPromoCards)

            dispatch({
                type: 'updatePromoCardsAndBanner',
                promoBanner: promoBanner?.promotions || [],
                promoCards: promoCards?.promotions || [],
            })
        } catch (error) {
            console.error(error)
        }
    }

    const fetchHubCategories = async () => {
        try {
            const { hubCategories } = await getHubsListAPIUrl(hubName, lang)
            const selectedHubCategory = findSelectedHubCategory(hubName, hubCategories, lang) || {}

            dispatch({
                type: 'updateHubCategories',
                hubCategories,
                selectedHubCategory,
            })
        } catch (error) {
            console.error(error)
        }
    }

    // fetch URL params by considering to provide make and model name in place of makeId and modelId if only one selection exist for make and model
    const fetchUrlParams = async () => {
        const {
            extractUrlParametersAsObject,
            extractPathUrlInfoAsObject,
            getHubPathName,
        } = require('../../utils/handle-url.utils')
        const hubName = initialHubName || getHubPathName(lang, location, orgUnitId)
        let urlParams = extractUrlParametersAsObject()
        const { makeName, modelName } = extractPathUrlInfoAsObject() || {}

        if (makeName) {
            const { data } = await getInventoryFiltersAPIUrl(hubName, lang)
            const { makeId, modelId } = data || {}
            const selectedMakeId = makeId && makeId.filter((make) => make.label.toLowerCase() === makeName)?.[0]?.key
            urlParams = { ...urlParams, ...{ makeId: [selectedMakeId] } }
            if (modelName) {
                const selectedModelId =
                    modelId && modelId.filter((model) => model.label.toLowerCase() === decodeURIComponent(modelName))?.[0].key
                urlParams = { ...urlParams, ...{ modelId: [selectedModelId] } }
            }
        }
        return urlParams
    }
    /**
     * Fetch updated inventory listing based on the filter selection
     * Fetch updated fitler data
     * pass the filter params from state
     */
    const fetchFilteredInventoryListing = async (urlParams) => {
        dispatch({ type: 'fetching' })

        try {
            // Get Inventory Listing - Vehicles
            const paymentOptions = typeof window !== 'undefined' ? getStorageValue('paymentOptions') : {}
            const {
                vehicles = [],
                pagination = {},
                paymentOptionsMetadata = [],
            } = await getInventoryListingApiUrl(hubName, lang, state.vehicleCriteria, '', paymentOptions)

            const promoCards = await getInventoryPromotionsApiUrl(lang, paramsPromoCards)
            dispatch({ type: 'updatePromoCards', promoCards: promoCards?.promotions || [] })

            const updatedVehicles =
                state.packageVersion === 'premium' && promoCards?.promotions?.length > 0
                    ? getVehiclesWithPromoTiles(
                          vehicles,
                          promoCards?.promotions,
                          state.XMSConfig?.configuration?.promotionsDisplaySequence
                      )
                    : vehicles

            dispatch({
                type: 'updateVehicles',
                vehicles: updatedVehicles,
                pagination,
                paymentOptions: paymentOptionsMetadata,
            })

            dispatch({ type: 'fetchSucceed' })
        } catch (error) {
            console.error(error)
        }
    }

    const fetchListingVehicles = async () => {
        dispatch({ type: 'fetching' })

        try {
            // Get Inventory Listing - Vehicles
            const paymentOptions = typeof window !== 'undefined' ? getStorageValue('paymentOptions') : {}
            const {
                vehicles = [],
                pagination = {},
                paymentOptionsMetadata = [],
            } = await getInventoryListingApiUrl(hubName, lang, state.vehicleCriteria, '', paymentOptions)

            const promoCards = await getInventoryPromotionsApiUrl(lang, paramsPromoCards)
            dispatch({ type: 'updatePromoCards', promoCards: promoCards?.promotions || [] })

            const updatedVehicles =
                promoCards?.promotions?.length > 0
                    ? getVehiclesWithPromoTiles(
                          vehicles,
                          promoCards?.promotions,
                          state.XMSConfig?.configuration?.promotionsDisplaySequence
                      )
                    : vehicles
            dispatch({
                type: 'updateVehicles',
                vehicles: updatedVehicles,
                pagination,
                paymentOptions: paymentOptionsMetadata,
            })

            dispatch({ type: 'fetchSucceed' })
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * Mixing promo tile into the vehicle array as per specified sequence
     * @param {*} oldVehicles - Vehicles return by API
     * @param {*} promotions - Promo card
     * @param {*} sequence - Promo card display sequence from delta
     * @returns Vehicle array with vehicles and promo tiles added into the selected sequence
     */
    const getVehiclesWithPromoTiles = (oldVehicles, promotions, sequence) => {
        let vehicles = oldVehicles && [...oldVehicles]
        let newPromoIndex = 0
        const promoSequence = typeof sequence === 'string' ? sequence.split(',') : sequence

        if (promoSequence) {
            vehicles =
                promoSequence &&
                promoSequence.reduce((acc, sequenceValue) => {
                    if (vehicles && sequenceValue - 1 < vehicles.length) {
                        acc.splice(sequenceValue - 1, 0, promotions[newPromoIndex])
                        newPromoIndex = (newPromoIndex + 1) % promotions.length
                    }
                    return acc
                }, vehicles)

            return vehicles
        }
        return vehicles
    }

    /**
     * Call to add/update the vehicle criteria on filter selection
     * This need to be called when select/deselect any filter to update the state
     * @param {*} vehicleCriteria
     * @param {*} criteria
     * @param {*} attribute
     * @param { Boolean } isFilter
     * @returns Object with updated vehicle criteria
     */
    const addVehicleCriteria = (vehicleCriteria, criteria, attribute, isFilter) => {
        let currentVehicleCriteria = vehicleCriteria
        let newCriteria

        // For filters such as makeId, modelId ... with multiple values
        if (Array.isArray(criteria)) {
            const filteredMetadatas = criteria.filter((metadata) => {
                return metadata.isSelected
            })
            newCriteria = filteredMetadatas.map((metadata) => {
                return metadata.code
            })
        } else {
            // For filters such as minPrice, maxPrice with single values
            // To check if the filter is an empty by removing the value
            if (isEmptyObject(criteria)) {
                newCriteria = { ...currentVehicleCriteria, [Object.keys(criteria)[0]]: '' }
            } else {
                newCriteria = { ...currentVehicleCriteria, ...criteria }
            }
        }

        // attribute - makeId, modelId etc...
        if (attribute) {
            currentVehicleCriteria = { ...currentVehicleCriteria, ...{ [attribute]: newCriteria } }
        } else {
            currentVehicleCriteria = newCriteria
        }

        if (isFilter && 'page' in currentVehicleCriteria) delete currentVehicleCriteria.page

        return currentVehicleCriteria
    }

    const getFilterMetaData = (filterData, filtersConfigurations, urlParams) => {
        // TODO: Remove filter condition after getting combined filter data from fitler API
        return (
            Object.entries(filterData)
                // .filter(([key]) => key in filtersConfigurations)
                .map(([name, value]) => {
                    let metadatas = []
                    if (value && Array.isArray(value)) {
                        metadatas = value
                            // .filter((metadata) => metadata.count > 0) // Display options that have count > 0
                            .map((metadata) => {
                                return new FilterMetaDataModel({
                                    ...metadata,
                                    code: metadata.key ? metadata.key : metadata.label,
                                    type: FILTER_OPTIONS_TYPES.CHECKBOX,
                                    variant: filtersConfigurations && filtersConfigurations[name]?.variant,
                                    // DO isSelected true for the selected filters metadata based on the URL params
                                    isSelected:
                                        urlParams[name] &&
                                        (Array.isArray(urlParams[name])
                                            ? urlParams[name].includes(metadata.key)
                                            : urlParams[name].split(',').includes(metadata.key.toString())),
                                })
                            })
                    }

                    if (value && isObject(value) && isNumber(value?.min) && isNumber(value?.max)) {
                        metadatas.push(
                            new FilterMetaDataModel({
                                label: 'range',
                                type: FILTER_OPTIONS_TYPES.RANGE,
                                min: value.min,
                                max: value.max,
                            })
                        )
                    }
                    const { displayOnNew, displayOnUsed, label, type, order, variant } = filtersConfigurations[name] || {
                        order: 0,
                    }

                    return {
                        name,
                        displayOnNew,
                        displayOnUsed,
                        label,
                        type,
                        order,
                        variant,
                        metadatas,
                    }
                })
        )
    }

    /**
     * Removes every filter from vehicleCriteria except sort and limit
     * @param {*} vehicleCriteria
     * @returns Object with updated vehicle criteria
     */
    const getEmptyCriteria = (vehicleCriteria) => {
        let newCriteria = {}
        Object.keys(vehicleCriteria).forEach((name) => {
            if (FILTER_OPTIONS_REMOVED_FROM_URL.includes(name)) {
                newCriteria = { ...newCriteria, [name]: vehicleCriteria[name] }
            }
        })
        return newCriteria
    }

    return (
        <ListingContext.Provider value={{ state, addVehicleCriteria, getEmptyCriteria, dispatch }}>
            {children}
        </ListingContext.Provider>
    )
}

ListingProvider.propTypes = {
    /** Initial state data from server side */
    initialData: PropTypes.object,
}

ListingProvider.defaultProps = {
    initialData: {},
}

export { ListingContext, ListingProvider }
